// /* GENERAL NODE HEADER */ //

.node-header {
	.node-header-details {
		clear: both;
		
		.card-authors-wrapper,
		.card-topics-wrapper,
		.card-date-wrapper,
		.card-date-updated,
		.card-location-wrapper,
		.card-organiser-wrapper,
		.card-cost-wrapper,
		.card-credits-wrapper,
		.card-display-date,
		.card-event-social-wrapper,
		.odd-wrapper, 
		.even-wrapper {
			width: 100%;
			margin-bottom: $vert-space*1.5;
			text-transform: none;
			
			@media (min-width: $narrow) {
				width: calc(50% - 10px);
				margin-bottom: 30px;
			}
			
			&, 
			.card-date,
			.card-location,
			.card-organiser,
			.card-date-display-override {					
				text-transform: none;
				font: normal 1rem/1.6 $base-font-family;
			}
		}

		@media (min-width: $narrow) {
			display: flex;
			flex-flow: row wrap;

			.card-authors-wrapper,
			.card-date-wrapper,
      .odd-wrapper {
				margin-right: $horz-space;					
			}
			
			.node-header-dates,
			.node-header-extra-info,
			.node-header-links,
      .node-header-row {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
			}
		}
		
		.label {
			font: 300 1.333rem/1 $title-font-family;
			color: $dark_purple;
			margin-bottom: $vert-space/2;
		}
		
		.card-display-date .card-display-date {
			width: 100%;
			margin: 0;
		}
	}
	
	.card-resource-summary {
		font-size: 1.222rem;
	}
}

//.node-type-resource-series {
//  .node-header {
//    .node-header-details {
//      .card-topics-wrapper {
//        margin-bottom: 0;
//        .label {
//          margin-top: $vert-space;
//        }
//      }
//    }
//  }
//}