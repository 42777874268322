.page-user {
  main section#content {
    .content-additional {
      background-color: transparent;
      padding: 0;
      .group-wrapper {
        background: $white;
        border-radius: 6px;
        padding: 30px;
        h1 {
          text-align: left;
        }
        .block-group {
          margin-bottom: 1em;
        }
        @include media($normal) {
          display: grid;
          grid-template-areas: 'header header header' 'groups groups groups ';
          grid-gap: 0 20px;
          h1 {
            grid-area: header;
          }
        }
      }
    }
    .block-members-directory {
      margin-top: 40px;
      .button {
        float: right;
      }
    }
  }
  h1#page-title {
    text-align: left;
  }
  .view-id-user {
    .card-prefix-id,
    .card-first-name,
    .card-last-name,
    .card-suffix-id {
      display: inline-block;
      margin-right: 0.25em;
      h2 {
        margin-bottom: 0;
      }
    }
    .card-relationship-type,
    .card-organization-name {
      display: inline-block;
    }
    .card-place-of-work-213 {

      color: #53565A;
      font-size: 1.222rem;
      line-height: 1.778;

      span {
        display: inline-block;
        &.job-title {
          margin-right: 10px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    .card-region-204,
    .card-place-of-work-213,
    .bgs-roles {
      margin-bottom: 30px;
    }
    .card-biography-221 {
      font-size: 1.222rem;
    }
    .user-label {
      color: $dark_purple;
      font: $title-font-family;
      font-size: 1.333rem;
      font-weight: 300;
      line-height: 1.667;
    }
    .card-research-expertise-270,
    .card-speaking-expertise-269 {
      display: inline-block;
    }
    .card-research-expertise-270 {
      margin-right: 2em;
      margin-bottom: 1em;
    }
    &.view-display-id-membership {
      .view-content {
        // margin-top: 30px;
        .card-membership-type,
        .card-status {
          margin-bottom: 30px;
        }
        &.not-member {
          margin-top: 0;
          .membership-footer {
            margin-top: 0 !important;
          }
        }
        .membership-details {
          padding: 20px 0;
        }
      }
    }
    &.view-display-id-alert {
      .body-text p:last-child {
        margin-bottom: 0;
      }
    }
    &.view-display-id-header {
      .card-image-URL {
        float: right;
        margin: 0 0 20px 20px;
        img {
          width: 190px;
          border-radius: 6px;
        }
        .empty-profile-image {
          width: 190px;
          height: 250px;
          background: $isabelline $bgs-shadow-icon no-repeat bottom 10px left 10px / 30%;
          border-radius: 6px;
        }
      }
    }
  }
  aside#sidebar-second {
    .view-id-user.view-display-id-manage_acc {
      margin-bottom: 1.5em;
      .side-button {
        margin-bottom: 10px;
      }
    }
    .membership-footer {
      border-top: 1px solid $pastel_gray;
      margin: 20px -20px 0;
      padding: 20px 20px 0;
      .button {
        margin-bottom: 20px;
        color: $dingy_dungeon;
        background: transparent;
        border: 2px solid $dingy_dungeon;
				white-space: normal;
				
        &:hover {
          background: $dingy_dungeon;
          color: $white;
        }
      }
    }
  }
  // #{$all-text-inputs},
  // select[multiple=multiple],
  // textarea {
  //   width: 50%;
  // }
  &.page-user-edit,
  &.page-user-payments {
    .block-members-directory {
      display: none;
    }
  }
  #personal-cf {
    display: none;
  }
  .mfp-content {
    background: $white;
    width: 50%;
    padding: 30px;
    textarea {
      height: 100px;
    }
    button.mfp-close:hover {
      color: $white;
    }
  }
}
.page-user-edit,
.page-user-payments {
  #{$all-text-inputs},
  select[multiple=multiple],
  textarea {
    width: 50%;
  }
  main section#content .content-additional .group-wrapper {
    display: none;
  }
}