fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea,
.crm-container	input.crm-form-text,
.crm-container	.crm-icon-picker-button,
.crm-container	input.dateplugin,
.crm-container	input.crm-form-password,
.crm-container	input.crm-form-submit {
	background: none white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/2.5 $horz-space/2;
	transition: border-color;
	width: 100%; 
	height: auto;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}

	&::placeholder {
		color: #9B9FA3;
	}
}

/* CIVICRM specific styles */
#crm-container.crm-public {
	.crm-section {
		padding: 10px;

		#{$all-text-inputs},
		select[multiple=multiple],
		textarea {
			background-color: white !important;
			border: $form-border !important;
			border-radius: $form-border-radius  !important;
			box-shadow: $form-box-shadow !important;
			box-sizing: border-box !important;
			font-family: $base-font-family !important;
			font-size: $base-font-size !important;
			margin-bottom: $vert-space/2 !important;
			padding: $vert-space/2.5 $horz-space/2 !important;
			transition: border-color !important;
			width: 50% !important; 

			&:hover {
				border-color: darken($form-border-color, 2%) !important;
			}

			&:focus {
				border-color: $action-default !important;
				box-shadow: $form-box-shadow-focus !important;
				outline: none !important;
			}

			&::placeholder {
				color: #9B9FA3 !important;
			}
		}
		&#editrow-gender_id,
		&#editrow-custom_365 .edit-value,
		&#editrow-custom_366 .edit-value,
		&#editrow-custom_206 .edit-value,
		&#editrow-custom_207 .edit-value,
		&#editrow-custom_208 .edit-value {
			label {
				margin-right: 10px;
			}
		}
		&#editrow-birth_date {
			.crm-form-date-wrapper {
				input {
					width: auto !important;
					float: left;
				}
			}
		}
		&#editrow-group {
			tbody {
				width: 100%;
				display: block;
				background-color: $white;
				font-size: 1rem;
				tr {
					width: 100%;
					display: block;
					td {
						display: grid;
						grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
						grid-gap: 10px 0;
					}
				}
			}
		}
		&#editrow-custom_107 {
			select {
				background: $arrow-purple-down no-repeat scroll 97% 50% !important;
			}
		}
		.select2-container .select2-choice > .select2-chosen {
			line-height: 1;
		}
		.edit-value.content {
			font-size: 1rem;
		}
		&.file_displayURL-section,
		&.file_deleteURL-section {
			display: none;
		}
		&.helprow-image_URL-section {
			padding-top: 0;
			padding-bottom: 0;
		}
		&.contact_image-section {
			padding: 0;
			.content {
				margin-left: 0;
			}
		}
		&#editrow-image_URL {
			padding-bottom: 0;
		}
		&.editrow_group-section {
			tr:hover > td {
				background: $white;
			}
		}
		&#editrow-tag {
			tbody {
				background: $white;
				.crm-section {
					padding: 0;
				}
				input {
					width: 100% !important;
				}
				ul.select2-choices {
					padding: 0;
					border: 0;
					background-image: none;
					li.select2-search-field {
						width: 100%;
					}
				}
			}
		}
		&#editrow-street_address-3,
		&#editrow-street_address-2,
		&#editrow-street_address-5 {
			border-top: 1px solid $dark_purple;
			padding-top: 3em;
			margin-top: 2em;
			border-radius: 0;
		}
		.crm-clear-link {
			display: none;
		}
		#UpdatePreference {
			.crm-option-edit-link.crm-hover-button {
				display: none;
			}
		}
	}
}
#crm-container.crm-public {
	.crm-submit-buttons {
		.crm-button {
			text-shadow: none;
			background: none;
			border: none;
			float: right !important;
			.crm-i {
				display: none;
			}
			input {
				background: $dingy_dungeon;
        border-radius: $button-border-radius;
        border: none;
        cursor: pointer;
        display: inline-block;
        font: $button-font;
        padding: $button-padding;
        text-decoration: none;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
        &:hover {
          background: $dark_purple;
        }
			}
		}
	}
}
#crm-container.crm-public {
	.crm-event-register-form-block,
	.crm-event-confirm-form-block {
		#editrow-street_address-5 {
			border-top: none;
			padding-top: 0;
			margin-top: 0;
			border-radius: 0;
		}
	}
	.crm-event-confirm-form-block {
		#crm-submit-buttons {
			.crm-button {
				float: left !important;
			}
		}
		.header-dark {
			background: transparent;
			color:$dark_purple;
			font-family:$title-font-family;
			margin-top:0;
			text-transform:none;
			font-weight: 300;
			font-size:1.556rem; //1.75em;
			border-bottom: 1px solid $dark_purple;
		}
		.crm-group {
			margin-bottom: 2em;
			.bold.crm-profile-view-title {
				display: none;
			}
			.crm-section .label {
				text-align: right;
			}
		}
	}
}
#crm-container .CRM_Event_Form_Registration_Register,
#crm-container .CRM_Gdpr_Form_UpdatePreference {
	.crm-public-form-item.crm-section.custom_pre-section {
		margin-bottom: 30px;
	}
	fieldset {
		border-top: none;
		legend {
			color:$dark_purple;
			font-family:$title-font-family;
			margin-top:0;
			text-transform:none;
			font-weight: 300;
			font-size:1.556rem; //1.75em;
			margin-bottom: 1em;
		}
		.label label {
			font-weight: 600;
		}
		#editrow-custom_324,
		#editrow-custom_325,
		#editrow-custom_357 {
			.content {
				display: table;
				input,
				label {
					float: left;
				}
				input {
					clear: left;
					margin-top: 5px;
				}
			}
		}
	}
	.comm-pref-block.groups-block {
		.section-description {
			margin-bottom: 1em;
		}
		.crm-section .content {
			margin-left: 10%;
			margin-right: 10%;
		}
		.data-policy-fieldset {
			.label {
				width: 0;
			}
			.content {
				margin-left: 2%;
			}
		}
	}
}
.page-user-edit-bgs-membership-17 {
	.crm-container .crm-section .label {
		width: 27%;
	}
	.crm-container .crm-section .content {
		margin-left: 29%;
	}
}
form#user-profile-form {
	#sig_orgs {
		.edit-value.content {
			margin-top: 1em;
			.row {
				display: grid;
				grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
				grid-gap: 10px 20px;
				.col-4 {
					display: flex;
					input {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}
.page-user-edit {
	#select2-drop input {
		margin-bottom: 0;
		padding: 0 10px;
	}
	// #user-profile-form #sig_orgs {
	// 	.row {
	// 		input {
	// 			float: left;
	// 			margin-right: 10px;
	// 			margin-top: 5px;
	// 		}
	// 	}
	// }
	label {
		color: $dark_purple;
		font-family: $title-font-family;
		font-weight: 300;
	}
	label[for="edit-1"] {
		font-size: 1.556rem;
	}
	.label label {
		font-size: 1.111rem;
		font-weight: 300;
	}
	// .crm-profile-name-BGS_Membership_17 {
	// 	padding: 30px 5%;
	// }
	#edit-timezone,
	#edit-field-member-alert {
		display: none;
	}
	#user-profile-form {
		#edit-account {
			.form-item {
				margin-bottom: 2em;
				.description {
					max-width: 50%;
				}
			}
		}
		#edit-contact {
			.form-item {
				margin-bottom: 2em;
				.description {
					max-width: 50%;
				}
			}
			padding: 0;
			legend {
				span {
					background: none;
					padding-left: 0;
					pointer-events: none;
					a {
						color: $dark_purple;
					}
				}
			}
		}
	}
	#select2-drop {
		input {
			width: 100%;
		}
	}
}

.select2-container .select2-choice,
select {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: auto;
	max-width: 100%;
	color: $primary;
	
	background: $ico-select no-repeat 100% 50% / 44px auto white;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: darken($form-border-color, 10%);
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

.crm-container.crm-public {
	.crm-section .label {
		padding-top: 8px;
	}
	
	.select2-container {
		.select2-choice {
			height: 41px;
			width: 200px;
			max-width: 200px; 
			color: $primary;

			&  > .select2-chosen {
				font-size: 1rem; 
			}

			.select2-arrow,
			abbr {
				display: none;
			} 

			.crm-container a,
			.crm-container a:link,
			.crm-container a:visited {
					color: $primary;	
			}
		}
	}
} 
.select2-drop.select2-with-searchbox {
	min-width: 200px !important;	
	border: $form-border;
	
	.select2-results {
		font-size: 1rem;
		color: $primary;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

.form-checkboxes,
.form-type-checkbox {
	input[type=checkbox] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	input[type=checkbox] + label.option {
		color: $imperial;
		display:inline-block;
		line-height:0.9rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		// font-size:1.5rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		&:hover {
			color: $dark_purple;
		}
	}
	input[type=checkbox]:checked + label.option {
		background: $checkbox-active no-repeat scroll 0 1px / 13px 13px transparent;
		color: $dark_purple;
	}
	label.option {
		background: $checkbox-default no-repeat scroll 0 0 / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: $checkbox-hover no-repeat scroll 0 0 / 13px 13px transparent;
			color:$dark_purple;
		}
	}
	
	.description {
		margin-left: 1.75em;
	}
}
 
.form-radio,
.form-type-radio { 
	input[type=radio] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	input[type=radio] + label.option {
		color: $imperial;
		display:inline-block;
		line-height:0.9rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		// font-size:1.5rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		&:hover {
			color: $dark_purple;
		}
	}
	input[type=radio]:checked + label.option {
		background: $radio-active no-repeat scroll 0 1px / 13px 13px transparent;
		color: $dark_purple;
	}
	label.option {
		background: $radio-default no-repeat scroll 0 0 / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: $radio-hover no-repeat scroll 0 0 / 13px 13px transparent;
			color:$dark_purple;
		}
	}
	
	.description {
		margin-left: 1.75em;
	}
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}



