.section-inner:empty {
	display: none !important;
}

a.card {
	.card-image-wrapper picture {
		overflow: hidden;
		
		img {
			transition: transform .2s ease-in;
			will-change: transform;
		}
	}
	
	&:hover {
		.card-image-wrapper picture img {
			transform: scale(1.05);
		} 
	}
}

main {
//	.content-main .view-sections > .view-content > .views-row {
//		border-bottom: $section-border; 
//
//		&.views-row-last {
//			border-bottom: none;
//		}
//	}
	
	.section-with-border-top {
		//border-top: $section-border;
		
		.section-inner {
			overflow: visible;
		}
	}
	
	.section-row {
		&:first-child {
			margin-top: 0;
			padding-top: 0;
		}
		@include media ($narrow) {
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
		@include palette-default-slick-controls;
		@include palette-default-button-link;

		.card { 
			@include palette-default-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-default-card-more-link;
		}		
		.card-feed-link {
			@include palette-default-card-feed-link;
		}
	}
}

.postscript-first .section-row {
	
	& > .view-content-main {	
		overflow: hidden;
	}
	
	.section-inner {
		@include outer-container($site-max-width);
	}
}

.postscript-first .section-row,
.content-hero .section-row {
	padding:$section-row-padding;
	 
	&.section-with-slider {
	 padding:0;
	}
	
	h2 {
		font:$section-row-title-font;
		.card-title {
			font-size: inherit;
		}
	}

	/* PALETTE DEFAULT */
	&.palette-default {
		background-color:$palette-default-bg-color;
		color:$palette-default-text-color;
		@include palette-default-button-link;
		@include palette-default-slick-controls;
	
		h2 {
			color:$palette-default-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-default-bg-color;
		}
		
		a {
			color:$palette-default-link-color;

			&:hover {
				color:$palette-default-link-hover;
			}
		}
		
		.card { 
			@include palette-default-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-default-card-more-link;
		}		
		.card-feed-link {
			@include palette-default-card-feed-link;
		}

	}

	/* PALETTE ALT-1 */
	&.palette-alt-1 {
		background-color:$palette-alt-1-bg-color;
		color:$palette-alt-1-text-color;
		@include palette-alt-1-button-link;
		@include palette-alt-1-slick-controls;
		
		h2 {
			color:$palette-alt-1-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-alt-1-bg-color;
		}
		
		a {
			color:$palette-alt-1-link-color;

			&:hover {
				color:$palette-alt-1-link-hover;
			}
		}
		
		.card { 
			@include palette-alt-1-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-alt-1-card-more-link;
		}		
		.card-feed-link {
			@include palette-alt-1-card-feed-link;
		}		
	}

	/* PALETTE ALT-2 */
	&.palette-alt-2 {
		background-color:$palette-alt-2-bg-color;
		color:$palette-alt-2-text-color;
		@include palette-alt-2-button-link;
		@include palette-alt-2-slick-controls;
		
		h2 {
			color:$palette-alt-2-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-alt-2-bg-color;
		}
		
		a {
			color:$palette-alt-2-link-color;

			&:hover {
				color:$palette-alt-2-link-hover;
			}
		}
		
		.card { 
			@include palette-alt-2-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-alt-2-card-more-link;
		}		
		.card-feed-link {
			@include palette-alt-2-card-feed-link;
		}		
	}

	/* PALETTE ALT-3 */
	&.palette-alt-3 {
		background-color:$palette-alt-3-bg-color;
		color:$palette-alt-3-text-color;
		@include palette-alt-3-button-link;
		@include palette-alt-3-slick-controls;
		
		h2 {
			color:$palette-alt-3-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-alt-3-bg-color;
		}
		
		a {
			color:$palette-alt-3-link-color;

			&:hover {
				color:$palette-alt-3-link-hover;
			}
		}
		.card { 
			@include palette-alt-3-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-alt-3-card-more-link;
		}		
		.card-feed-link {
			@include palette-alt-3-card-feed-link;
		}		
	}

		/* PALETTE HIGHLIGHT */
	&.palette-highlight {
		background-color:$palette-highlight-bg-color;
		color:$palette-highlight-text-color;
		@include palette-highlight-button-link;
		@include palette-highlight-slick-controls;
		
		h2 {
			color:$palette-highlight-title-color;
		}
		& > h2 .card-title span {
			background-color: $palette-highlight-bg-color;
		}
		
		a {
			color:$palette-highlight-link-color;

			&:hover {
				color:$palette-highlight-link-hover;
			}
		}
		
		.card { 
			@include palette-highlight-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-highlight-card-more-link;
		}		
		.card-feed-link {
			@include palette-highlight-card-feed-link;
		}		
	}
}
