html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	color:$dark_purple;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$base-font-family;
	}
	&.card-title-sm {
		font-family:$base-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:300;
}
h3, h4, h5, h6 {
	font-weight:300;
}

//.l-region--sidebar-second {
//	h3, h4, h5, h6 {
//		font-family:$base-font-family;
//	}
//}

h1 {
	font-size:2rem; //2.250rem;
	line-height:1.1875;
	margin-bottom:$vert-space;
	text-align: left;
	clear: both;
	
	@include media($normal) {
		font-size:2.333rem; //2.625rem;
		line-height: 1.33333333333333;
		text-align: center;
	}
}
h2 {
	font-size:1.778rem; //2rem;
	line-height:1.32;
	margin-bottom:0.5em;
	//font-weight: 600;

	@include media($normal) {
		font-size:2rem; //2.250rem;
		line-height: 1.28571428571429;
	}
	
	.card-title {
		text-align: center;
	}
}
h3 { 
	font-size:1.556rem; //1.75rem;
	line-height:1.375; /*1.273;*/
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size:1.778rem; //2rem;
		line-height: 1.33333333333333;
	}
}
h3.block__title {
	
	font-size:1.556rem; //1.75em;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 2rem; //2.250rem;
	}
}
h4 {
	font-size:1.333rem; //1.5rem;
	line-height:1.375; /*1.3;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.556rem; //1.75rem;
		line-height: 1.36363636363636;
	}
}
h5 {
	font-size:1.111rem; //1.250rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.333rem; //1.5rem;
		//line-height: 1.42105263157895;
	}
}
h6 {
	font-size:1.063rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.111rem; //1.250rem;
		line-height: 1.42105263157895;
	}
}
p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.778rem;
				line-height: 1.5;
				
				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.222rem;
				line-height: 1.3;
				p, li {
					font-size: 1em;
				}
			}				
			@include media($normal) {
				font-size:1.125rem;
				line-height: 1.44444444444444;
				
				&.smaller-text {
					font-size: 0.778rem;
					line-height: 1.5;
				}
				&.larger-text {
					font-size: 1.222rem;
					line-height: 1.5;
				}				
			}
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.778rem;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.889rem;
					line-height: 1.5;
				}
			}
		}
	}
}
b, strong {
	font-weight:700;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

//aside {
//	a, a:link, a:active, a:visited {
//		color:$action-alt-default;
//		text-decoration:none;
//
//		&.active {
//			color:$action-alt-active;
//			text-decoration:none;
//		}
//
//		&:hover,
//		h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
//			color:$action-alt-hover;
//			text-decoration:none;
//		}
//
//		&:not([href]) {
//			color: inherit;
//		}
//	}
//}

.date,
.card-date {
	font-size:0.750em;
	font-style:normal;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: 0.778rem;
	&:before {
		content: "";
		margin-left: 0.15em; 
	}
}
sup { vertical-align: super; top: 0; }
sub { vertical-align:sub; bottom: -0.15rem;}

blockquote {
	width: 90%;
	position: relative;
	@include media($narrow) {
		width: 80%;
	}
	background-color: $glitter;
	padding: 30px;
	margin: 30px auto;
	
	p {		
		color: $blockquote-text-color;
		font: 300 1.444rem/1.5 $title-font-family !important;
			
		&.smaller-text {
			font: 300 1.111rem/1 $base-font-family  !important;
		}
	} 
	
	&:before {
		display: block;
		position: absolute;
		content: "";
		height: 43px;
    width: 42px;
    left: -25px;
    top: 20px;
		background: $ico-blockquotes-blue no-repeat scroll 0 0 / 100% 100% transparent; 
		
		@include media($narrow) {
			height: 43px;
			width: 52px;
			left: -40px;
			top: 25px;
		}
	}
}

.front blockquote {
	background-color: transparent;
	margin: 0 auto;
	padding: 30px 30px 0;
	
	p {		
		color: $blockquote-text-alt-color;
		font: 300 1.778rem/1.25 $title-font-family !important;
			
		&.smaller-text {
			font: 300 1.222rem/1.25 $base-font-family  !important;
			color: $davys_grey;
		}
	} 
	&:before {
		background: $ico-blockquotes-dark-purple no-repeat scroll 0 0 / 100% 100% transparent; 
	}
}

@media screen and (min-width: 60em) {
	main,
	.postscript-first {
		.card-body,
		.card-description{
			p, 
			address, 
			pre, 
			ul, 
			ol {		
				font-size: 1rem;
				line-height: 1.5;
			}
		}
	}
}

.highlighted-div {
	background-color: $platinum;
	padding: 30px;
}


.postscript-section-row-block > h2 {
	text-align: center;
}