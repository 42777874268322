body {

	word-wrap: break-word;
	
  main {
    padding: $section-main-padding;
    display: flex;
    flex-wrap: wrap;
		
		section#content {
			overflow: hidden;
		}
  }

  &.no-sidebars {
    main { 
      section#content {
        width: 100%;
				padding:0 $horz-space/2;
      }
      
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 0;
          
          @include media($narrow) {
            width: calc(100%/12 -  #{$gutt}); ///2);
          }
					@media (max-width:$narrow) {
						order: 3;
					}
        }
      }

      section#content {
        width: 100%;
        margin: calc(#{$gutt} / 2);
				margin-top: 0;
				overflow: hidden;
				
        @include media($narrow) {
          width: calc(100%/12*11 -  #{$gutt}); //*2);
					margin-right: 0;
        }
      }
	 	}
  }

  &.one-sidebar.sidebar-second {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;} 
      aside {
        &#sidebar-second {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 0;
          @include media($narrow) {
            width: calc(100%/3 -  #{$gutt});
          }
					
          @include media($wide) {
            width: calc(100%/4 -  #{$gutt});
          }
        }
      }

      section#content {
        width: 100%;
				margin: 0 calc(#{$gutt} / 2) calc(#{$gutt} * 2);
				
        @include media($narrow) {
          width: calc(100%/3*2 -  #{$gutt});
        }
				
				@include media($normal) {
					margin: 0 calc(#{$gutt} / 2) calc(#{$gutt} / 2);
				}
				
        @include media($wide) {
          width: calc(100%/4*3 -  #{$gutt});
        }
      }
    }
  }

  &.two-sidebars {
		
		main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 0;
					
          @media (max-width:$narrow) {
						order: 3;
					}
          @include media($narrow) {
            width: calc(100%/12 -  #{$gutt}/2);
          }
          @include media($normal) {
            width: calc(100%/12 -  #{$gutt}/2);
          }
        }

			  &#sidebar-second {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 0;

          @media (max-width:$narrow) {
						order: 2;
					}
          @media (min-width:$narrow) and (max-width:959px) {
						margin-left: calc(100%/12 + 20px);
    				width: calc(100% - calc(100%/12) - #{$gutt}/2);
          }
          @include media($normal) {
            width: calc(100%/4 -  #{$gutt}/2);
          }
        }
			}

      section#content {
        width: 100%;
        margin: 0 calc(#{$gutt} / 2) calc(#{$gutt} * 2);

        @media (max-width:$narrow) {
					order: 1;
				}

				@include media($narrow) {;
					width: calc(100%/12*11 - #{$gutt}*1.5);
        }
        @include media($normal) { 
          width: calc(100%/1.5 - #{$gutt}*2);
					margin: 0 calc(#{$gutt} / 2) calc(#{$gutt} / 2); 
        }
      }
    }
  }
}