#site-footer {
	.outer-wrapper {
		padding:0 $horz-space;
	}

	h2 {
		color:$footer-first-text;
		font-size: 1.778rem;
	}
	
	ul:not(.contextual-links) {
		padding: 0;

		li {
			list-style: none;
			line-height: 1.5em;
		}
	}
	
	.card-follow-us-on ul li { display: inline-block; }


	a.arrow {
		font-weight: 600;
    text-transform: uppercase;
		color:$united_nations_blue !important;
		padding-right: 35px;
		background: $arrow-long-blue no-repeat scroll 100% 40% / 26px 14px transparent;
		&:hover {
			color:$footer-first-action-hover !important;
			background: $arrow-long-rose no-repeat scroll 100% 40% / 26px 14px transparent;
		}
	}
	
	.section-row {
    padding: $vert-space 0;
		
		@include media($normal) {
			padding: $vert-space*2 0;
		}
	}
	
	.footer-first {
		background: $footer-first-bg;
		color: $footer-first-text;
		padding:$vert-space 0;
		@include palette-footer-first-slick-controls;
		
		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}
		
		.card {
			@include palette-footer-first-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}		

		.card-body {
			font-size: 1.111rem;
			color: $pastel_gray;
			
			h1, h2, h3, h4, h5, h6 {
				color: white;
				margin-bottom: 0;
				font-weight: 600;
				font-family: $base-font-family;
			}
			
			p {
				margin-bottom: 1rem;
			}
			
			a {
				text-transform: uppercase;
				font: 600 0.889rem $title-font-family;
			}
			a.button {
				background: $button-link-default-bg;
				color: $button-link-default-color;
				&:hover {
					background:$button-link-default-hover-bg;
					color: $button-link-default-hover-color;
				}
			}
		}
		
		a {
			color:$footer-first-action;
			&:hover {
				color:$footer-first-action-hover;
			}
		}
	}
	
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;
		
		a {
			color:$footer-second-action;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}		
		
		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;
			
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
		}
		
	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		font-size: 1rem;
		
		a {
			color:$footer-third-action;
			&:hover {
				color:$footer-third-action-hover;
			}
		}
		#manta-ray-media {
			float: none;
			@include media(880px) {
				float: right;
			}
			a {
				color: $action-default;
				&:hover {
					color: $action-hover;
				}
			}
		}
		.block-menu {
			float: none;
			@include media(880px) {
				float: left;
			}
			ul {
				margin: 0;
				li {
					display: inline-block;
					margin-right: 5px;
					padding-right: 10px;
					border-right: 1px solid $secondary-border-color;
					
					&:after {
						content: " • ";
						margin-left: 15px;
						color: $sonic_silver;
					}
					
					a {
						color:$footer-third-action;
						&:hover {
							color:$footer-third-action-hover;
						}
					}
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
					
					&:after {
						display: none;
					}
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 12px;
		text-indent: -9999px;
		
		a {
			background-color: $card-links-action-bg;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			border: $card-links-border;
			background-size: 20px 20px !important;
			
			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				background-color: $card-links-hover-bg;
			}
		}
		
		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px $footer-first-bg;
			&:hover {
				background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $action-default;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-color no-repeat scroll 50% 50% / 19px 18px $footer-first-bg;
			&:hover {
				background: $ico-linkedin-dark no-repeat scroll 50% 50% / 19px 18px $action-default;
			}
		}
		
		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"], 
		a[href^="http://twitter.com"] {
			background: $ico-twitter-color no-repeat scroll 50% 50% / 20px 20px $footer-first-bg;
			&:hover {
				background: $ico-twitter-dark no-repeat scroll 50% 50% / 20px 20px $action-default;
			}
		}

		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-color no-repeat scroll 50% 50% / 18px 18px $footer-first-bg;
			&:hover {
				background: $ico-facebook-dark no-repeat scroll 50% 50% / 18px 18px $action-default;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-color no-repeat scroll 50% 50% / 19px 18px $footer-first-bg;
			&:hover {
				background: $ico-gplus-dark no-repeat scroll 50% 50% / 19px 18px $action-default;
			}
		}
	}


	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	} 
}
