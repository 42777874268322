.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}


.views-exposed-form label {
	font: 400 1.333em/1 $title-font-family;
	display: inline-block;
	color: $dark_purple;
	min-width: 130px;
}

//select {
//	background: $ico-select no-repeat 100% 50% / 44px auto white;
//	appearance: none;
//	-webkit-appearance: none;
//	-moz-appearance: none;
//}


// /* SEARCH PAGE BY CONTENT TYPE FILTER */
.search-page-content-type,
.search-page-content-type-tpl {		
	display: flex;
	flex-flow: column wrap;
	position: relative;

	.view-header,
	.view-footer {
		order: 2;
		min-height: 65px;

		.view-results {
			font-size: 1.222rem;
			float: left;
		}

		.view-mode-label {
			@media(max-width:719px) {
				display: none;
			}
		}
	}
	
	.view-footer {
		min-height: 115px;
	
		@media(min-width:540px) {
			min-height: 65px;
		}
	}

	.view-filters {
		order: 1;
		background-color: white;
		margin-bottom: 30px;

		.views-exposed-widgets {
			display: flex;
			flex-flow: row wrap;
			position: relative;
			margin: 0;
			padding-top: 20px;

			#edit-keywords-wrapper {
				order: 1;
				width: 630px;
				max-width: 100%;
				padding-left: 1em;

				@media(min-width:$narrow) {
					max-width: calc(100% - 280px);
					padding-left: 0;
				}

				label {
					display: none;
				}
			}
			.views-submit-button {
				order: 2;						
				padding-left: 1em;

				@media(min-width:$narrow) {
					padding-left: 0;
				}

				.form-submit {
					margin-top: 0px;
				}
			}
			.views-reset-button {
				order: 3;

				@media(min-width:$normal) {
					margin-right: 20px;
				}

				.form-submit {
					margin-top: 0px;
					background-color: white;
					border: 2px solid $dingy_dungeon;
					color: $dingy_dungeon;
					padding: 0.556rem 0.9em;

					&:hover {
						background-color: $dark_purple;
						border: 2px solid $dark_purple;
						color: white;
					}
				}
			}

			#edit-field-clinical-research-type-tid-wrapper {
				order: 4;
				padding-left: 20px;

				.bef-checkboxes .form-item {
					display: inline-block;
					margin-right: 10px;
					padding: 8px 0;
				}
			}

			.views-widget-filter-secondary {
				order: 5;
				flex: 1 100%;
				margin-top: 30px;
				padding: 0;

				fieldset.form-wrapper {
					padding: 0;	
					height: auto;
					margin: 0;

					legend {
						width: 100%;

						.fieldset-legend {
							background: none;
							padding-left: 0;

							span.summary {
								display: none;
							}

							a {
								display: block;
								padding: 20px 30px;
								width: 100%;
								font: 300 1.556em/1 $title-font-family;
								color: $green_blue;
								background: $ico-collapse-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
								border: 1px solid $azureish_white;
							}
						}
					}
					
					&.collapsed legend .fieldset-legend a {
						background: $ico-expand-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
					}

					.fieldset-wrapper {
						padding: 30px 30px 20px;

						.form-item {
							width: 100%;
							//min-width: 400px;

							select,
							.views-widget {
								width: 100%;
								max-width: calc(100% - 140px);
								display: inline-block;

								@media(min-width:$narrow) {
									width: 420px;
									max-width: calc(100% - 210px);
								}
							}

							@media(min-width:$normal) {
								width: calc(50% - 10px);
								display: inline-block; 

								select {
									min-width: 225px;
								}
							}
							
							.form-item {
								width: 100%;
							}
						}						
						
						#edit-field-date-value-value-wrapper,
						#edit-field-start-date-value-value-wrapper {
							.description {
								display: none;
							}
							input {
								display: inline-block;
								background: $ico-calendar no-repeat 100% 50% / 44px auto transparent;
							}
						}
					}					
				}
			}

			.views-widget-sort-by {
				order: 6;
				position: absolute;
				bottom: -128px;
				right: 0;
				padding: 0;
				
				@media(min-width:540px) {
					bottom: -78px;
				}				

				label {
					display: none;

					@media(min-width:$normal) {
						display: inline;
						font: 400 1.111rem/1 $title-font-family;
						color: $dark_purple;
						padding-right: 5px;
					}
				}

				select {
					display: inline;
					min-width: 200px;
				}
			}
		}
	}

	.view-content {
		order: 3;
	}
	.item-list {
		order: 4;
	}
	.view-empty {
		order: 5;
		margin-top: 60px;
	}
}

// /* FOR POLICY AND BLOG PAGES */ //
.search-page-content-type-tpl {
	
	.view-header { 
		order:1;
		padding: 20px 30px 30px;
		background-color: white;

		.views-row {
			width: 100%;
		}
	}
	.view-filters {
		order: 2;
		
		.search-toggle {
			display: block;
			padding: 20px 30px;
			width: 100%;
			font: 300 1.556em/1 $title-font-family;
			color: $green_blue;
			background: $ico-expand-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
			border: 1px solid $azureish_white;
			cursor:pointer;
		}
		
		.views-exposed-form {
			display:none;
			padding: 20px 30px;
			
			.views-exposed-widgets .views-widget-filter-secondary fieldset.form-wrapper .fieldset-wrapper {
				
				.views-exposed-widget {
					padding-top: 0;
				}
				
				#edit-keywords-wrapper {
					// order: 1;
					padding-left: 0;
					width: 100%;
					
					.views-widget {
						max-width: 100%;
						width: 100%;
					}
					
					@media(min-width:$narrow) { 
						width: 570px;
					}
					
					//@media(min-width:$narrow) { 
					//	width: 630px;
					//}
					
				}
				.views-submit-button {
					// order: 2;					
					padding-left: 0;
				}
				.views-reset-button {
					// order: 3;
					margin-bottom: $cards-separation-alt;
					margin-right: 0;
					padding-right: 0;
				}
				// #edit-type-selective-wrapper {
				// 	order: 4;
				// }
				// #edit-tag-wrapper {
				// 	order: 5;
				// }
				// #edit-from-date-wrapper {
				// 	order: 6;
				// 	width: 100%;
				// }
				// #edit-authors-wrapper {
				// 	order: 7;
				// }
				// #edit-to-date-wrapper {
				// 	order: 8;
				// 	width: 100%;
				// }

				.form-item select {
					max-width: 100%;
				}
				
				#edit-from-date-wrapper,
				#edit-to-date-wrapper {
					& > label {
						padding-top: 10px;
					}
					#edit-field-date-value-value-wrapper input {
						margin-top: -10px;
					}
				}
			}
		}
		
		.views-widget-sort-by {
			position: absolute;
			right: 0;
			
			padding: 56px 0 0 0;
    	width: 100%;
			
			@media(min-width:540px) {
				padding: 6px 0 0 0;
				width: auto;
			}

			label {
				display: none;

				@media(min-width:$normal) {
					display: inline;
					font: 400 1.111rem/1 $title-font-family;
					color: $dark_purple;
					padding-right: 5px;
				}
			}

			select {
				display: inline;
				min-width: 200px;
				
				width: 100%;
			
				@media(min-width:540px) {
					width: auto;
				}
			}
		}
	}
	.view-footer {
		order: 3;
	}
	.view-content {
		order: 4;
	}
	.item-list {
		order: 5;
	}
	
	.view-filters {
		.views-exposed-form {
			padding: 0;

			.views-exposed-widgets {
				padding: 0;
				
				.views-widget-filter-secondary {
					margin-top: 0;
				}
			}
		}
	}
	
	&.search-expanded {
		.view-filters {
			.search-toggle {
				background: $ico-collapse-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
			}
			.views-exposed-form {
				display:block;
			}
		}
	}
}
.view-id-news_and_policy_bgs.view-display-id-page .view-filters .views-exposed-widgets .views-widget-filter-secondary fieldset.form-wrapper .fieldset-wrapper {
	#edit-keywords-wrapper {order: 1;}
	.views-submit-button {order: 2;}
	.views-reset-button {order: 3;}
	#edit-type-selective-wrapper {order: 4;}
	#edit-tag-wrapper {order: 5;}
	#edit-authors-wrapper {order: 6;}
	#edit-from-date-wrapper {order: 7;}
	#edit-to-date-wrapper {order: 8;}
	@media(min-width:720px) {
		#edit-keywords-wrapper {order: 1;}
		.views-submit-button {order: 2;}
		.views-reset-button {order: 3;}
		#edit-type-selective-wrapper {order: 4;}
		#edit-from-date-wrapper {order: 5;}
		#edit-tag-wrapper {order: 6;}
		#edit-to-date-wrapper {order: 7;}
		#edit-authors-wrapper {order: 8;}
	}
}
.view-id-blog.view-display-id-page .view-filters .views-exposed-widgets .views-widget-filter-secondary fieldset.form-wrapper .fieldset-wrapper {
	#edit-keywords-wrapper {order: 1;}
	.views-submit-button {order: 2;}
	.views-reset-button {order: 3;}
	#edit-tag-wrapper {order: 4;}
	#edit-authors-wrapper {order: 5;}
	#edit-from-date-wrapper {order: 6;}
	#edit-to-date-wrapper {order: 7;}
	@media(min-width:720px) {
		#edit-keywords-wrapper {order: 1;}
		.views-submit-button {order: 2;}
		.views-reset-button {order: 3;}
		#edit-tag-wrapper {order: 4;}
		#edit-from-date-wrapper {order: 5;}
		#edit-authors-wrapper {order: 6;}
		#edit-to-date-wrapper {order: 7;}
	}
}

.page-resources,
.page-policy-and-media,
.page-blog,
.page-about-our-purpose-members-directory,
.page-events,
.page-events-external-events,
.page-events-external-courses,
.page-my-events-and-cpd-points,
.page-about-external-vacancies,
//.page-jobs, 
.page-jobs-external-jobs {
	main section#content {
	
		h1 {
			padding: 30px 30px 0;
			background-color: white;
			margin: 0;
			text-align: left;
		}

		.content-main {
			background-color: transparent;
			padding: 0;
		}

		.search-page-content-type,
		.search-page-content-type-tpl {
			.bef-secondary-options {
				display:flex; 
				flex-wrap: wrap;

				//.form-item {width: 100%;}
				.form-item-type {order: 1;}
				.form-item-tag {order: 2;}
				.form-item-authors {order: 3;}
				.form-item-field-resource-series-target-id-selective {order: 4;}
				.form-item-date-from {order: 5;}
				.form-item-date-to {order: 6;}
				@media(min-width:720px) {
					.form-item-type {order: 1;}
					.form-item-date-from {order: 2;}
					.form-item-tag {order: 3;}
					.form-item-date-to {order: 4;}
					.form-item-authors {order: 5;}
					.form-item-field-resource-series-target-id-selective {width:100%; order: 6;}
				}
			}
		}
	}
}


.page-events-external-events,
.page-events-external-courses  {
	.search-page-content-type-tpl .view-filters .views-exposed-form {
    display: block;
		
		.views-submit-button,
		.views-reset-button {
			display: none;
		}
	}
}
// .page-events  {
// 	.search-page-content-type-tpl .view-filters {
// 		.views-exposed-form {
// 			.views-submit-button,
// 			.views-reset-button {
// 				padding-top: 0;
// 		    @include media($narrow) {
// 					padding-top: 30px;
// 				}
// 	    }
// 		}
// 	} 
// }

.view-id-member_directory.view-display-id-page,
.view-id-events_page.view-display-id-page,
.view-id-events_page.view-display-id-page_1 {
  .view-filters .views-exposed-form {
    display: block !important;
    #edit-keywords-wrapper {
			width: 100%;
			padding: 30px 30px 10px;
			@include media($narrow) {
				padding: 30px;
			}
			// padding-left: 0;
			@include media($narrow) {
				width: 630px;
			}
    }
    .views-exposed-widget.views-submit-button,
    .views-exposed-widget.views-reset-button {
			padding-top: 0;
			@include media($narrow) {
				padding-top: 30px;
			}
    }
    .views-exposed-widget.views-submit-button {
    	padding-left: 30px;
    	padding-bottom: 30px;
    	@include media($narrow) {
    		padding-left: 0;
    	}
    }
    .bef-secondary-options {
      .form-item {
        margin-bottom: 1em;
        &.form-type-checkbox {
          width: calc(25% - 10px);
        }
      }
    }
  }
  #edit-secondary .bef-secondary-options {
    display: flex;
    @include media($narrow) {
	    .form-item-sig {order: 1; width: 50%;}
	    .form-item-research-expertise {order: 2; width: 50%;}
	    .form-item-name {order: 3; width: 50%;}
	    .form-item-speaking-expertise {order: 4; width: 50%;}
	    .form-item-region {order: 5; width: 50%;}
	    label {
	      // width: 250px;
	    }
    }
  }
}
.view-id-member_directory.view-display-id-page {
	#edit-secondary-wrapper .bef-secondary-options{
		label {
			min-width: 310px;
			font-size: 1.111rem;
		}
		select {
			width: 420px;
		}
	}
}
.view-id-events_page.view-display-id-page,
.view-id-events_page.view-display-id-page_1 {
	#edit-secondary .bef-secondary-options {
	  display: flex;
	  @include media($narrow) {
	  	.form-item {
	  		margin-bottom: 0;
	  	}
			.form-item-event-type {order: 1; width: 50%;}
			.form-item-topics {order: 3; width: 50%;}
			.form-item-region {order: 5; width: 50%;}
			#edit-field-start-date-value-value-wrapper.from-date {
				order: 2; 
				width: 50%; 
				.form-item-from-date-value-date {
					// margin-bottom: 0;
				}
				.form-type-date-popup {
					width: 100%;
				}
			}
			#edit-field-start-date-value-value-wrapper {
				order: 4; 
				width: 50%; 
				.form-item-from-date-value-date {
					// margin-bottom: 0;
				}
				.form-type-date-popup {
					width: 100%;
				}
			}
	  }
	  label {
	    min-width: 130px;
	    display: inline-block;
	  }
	}
}
//.view-resources-bgs.search-page-content-type {
//	.bef-secondary-options {
//		display:flex;
//		flex-wrap: wrap;
//		
//		.form-item {width: 100%;}
//		.form-item-type {order: 1;}
//		.form-item-tag {order: 2;}
//		.form-item-authors {order: 3;}
//		.form-item-field-resource-series-target-id-selective {order: 4;}
//		#edit-field-date-value-value-wrapper {width: 100%; order: 5;}
//	}
//}


// /* GRID LIST */
.layout-trigger {
	color: $dark_purple;
	font: 400 1.111rem/1 $title-font-family;
	float: right;
	margin-right: 220px;
	position: relative;
	z-index: 1;
	
	@media(min-width:$normal) {
		margin-right: 300px;
	}
	
	@media(max-width:539px) {
		display: none;
	}
	
	.trigger-list,
	.trigger-grid {
		cursor: pointer;
		display: inline-block;
		height: 32px;
		width: 72px;
		padding: 8px 10px;
		font: 500 0.889rem/1 $base-font-family;
		color:$primary;	
		
		@media(max-width:719px) {
			text-indent: -9999px;
    	width: 42px;
    	background-position-x: 50% !important;
		}
	}
	.trigger-list {
		background: $trigger-list no-repeat 87% 50% / 20px auto $isabelline;
		
		//&:hover { background: $trigger-list-hover no-repeat 87% 50% / 20px auto $isabelline; color:$dark_purple;}
		&:hover { background: $trigger-list-active no-repeat 87% 50% / 20px auto $isabelline; color:$bleu_de_france;}
		
		&.active,
		&.active:hover { background: $trigger-list-active no-repeat 87% 50% / 20px auto white; color:$bleu_de_france;}
		
		//&.active:hover { background: $trigger-list-hover no-repeat 87% 50% / 20px auto white; color:$dark_purple;}
	}
	.trigger-grid {
		margin-left: 10px;
		background: $trigger-grid no-repeat 87% 50% / 20px auto $isabelline;
		
		//&:hover { background: $trigger-grid-hover no-repeat 87% 50% / 20px auto $isabelline; color:$dark_purple;}
		&:hover { background: $trigger-grid-active no-repeat 87% 50% / 20px auto $isabelline; color:$bleu_de_france;}
		
		&.active,
		&.active:hover{ background: $trigger-grid-active no-repeat 87% 50% / 20px auto white; color:$bleu_de_france;}
		
		//&.active:hover { background: $trigger-grid-hover no-repeat 87% 50% / 20px auto white; color:$dark_purple;}
	}
}

// Keywords search icon
.form-item-keywords input.form-text {
	background: $search-icon-purple no-repeat scroll 97% 50% / 14px 14px;
	padding-right: 40px;
}


// /* CALENDAR POPUP */ //

#ui-datepicker-div {
	border: 1px solid #7B4D81;
	
	.ui-datepicker-header {
		background: none;
    border: none;
		
		.ui-datepicker-title {
			width: 230px;
			margin: 0 2.3em 0 1.8em;
			
			select {
				width: 49%;

				&.ui-datepicker-year {
					width: 51%;
				}
			}
		}
		
		.ui-datepicker-prev, 
		.ui-datepicker-next {
			top: 25%;
			width: 1.5rem;
			
			&.ui-datepicker-prev-hover, 
			&.ui-datepicker-next-hover {
				background: none;
				margin: 0;
			}
		}
		
		.ui-datepicker-prev {
			.ui-icon {
				background: $arrow-purple-left no-repeat scroll 50% 50% / 10px auto;
			}
			
			&.ui-datepicker-prev-hover {
				left: 2px;
				border: none;
				
				.ui-icon {
					background: $arrow-blue-left no-repeat scroll 50% 50% / 10px auto;
				}
			}
		}
		
		.ui-datepicker-next {
			.ui-icon {
				background: $arrow-purple-right no-repeat scroll 50% 50% / 10px auto;
			}
			 
			&.ui-datepicker-next-hover {
				right: 2px;
				border: none;
				
				.ui-icon {
					background: $arrow-blue-right no-repeat scroll 50% 50% / 10px auto;
				}
			}
		}
	}
	table {
		border: none;
		width: 100%;
		
		thead,
		tbody {
    width: 100%;
    border: none;
		}
		
		thead {
			th:first-child {
				border-left: 0;
			}
		}
		
		tbody {
			tr,
			tr:last-child td {
    		border: none;
				
				td {
					border: 0;
					padding: 1px;
					width: 42px !important;
					max-width: 42px !important;
					min-width: 42px !important;
					
					&:first-child {
						border-left: none;
					}    
				}
			}
		}
		
		.ui-state-default {
			background: none #EFEFF0;
		}
		
		.ui-state-highlight {
			//color: #266FB8;
    	background-color: #E1EDFB;
		}	
		
		.ui-state-hover {
			color: #266FB8;
    	background-color: #E1EDFB;
			border-color: #266FB8;
		}
	}
}

// HIDE GROUP FILTER

div#edit-group-wrapper {
    display: none;
}
