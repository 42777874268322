$section-row-padding          : $vert-space*1.5 0 !default;
$section-inner-mobile-padding : 0 $horz-space/2 !default;
$section-inner-padding        : 0 $horz-space !default;



.section-row {
	padding:$section-row-padding;
	
	&.section-with-slider {
		padding:0;
	}
	
	.section-inner {
		overflow: hidden;
		clear: both;
	}
}

.content-main .section-row {
	padding:$section-row-content-padding;
}
 
.postscript-first .section-row .section-inner {
	@include outer-container($site-max-width);
	padding:$section-inner-mobile-padding;
	@media (min-width:$narrow) {
		padding:$section-inner-padding;
	}
}

.section-row:not(.view-type-slider) {
	
	
	@media (max-width:539px) {
		
		.views-row {
			@include card-radious-1-card();
		}
	}
	

	@media (min-width:540px) and (max-width:719px) {
	
		&.row-max-1, &.row-max-2, &.row-max-3, &.row-max-4, &.row-max-6, &.row-max-9 {
			 .views-row {
				 margin-right:0;
				 
				 .card {
						margin-bottom:$vert-space;
						margin-right:0;
					}
			 }
		}
		
		&.row-max-12 .views-row {
			@include span-columns(1);
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-6 .views-row,
    &.row-max-4 .views-row,
    &.row-max-3 .views-row,
    &.row-max-2 .views-row {
      @include span-columns(6);			

			width: calc(50% - #{$cards-separation});
			margin-right: $cards-separation;

			&:nth-child(2n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(2n+1) { clear:left; @include card-radious-left(); }
		}
		
		&.row-max-1 .views-row {
			@include card-radious-1-card();
		}
			
			
		&.row-max-12 .card-last-link-url {
			@include span-columns(1);
		}
    
		&.row-max-6 .card-last-link-url,
		&.row-max-4 .card-last-link-url
		&.row-max-3 .card-last-link-url,
		&.row-max-2 .card-last-link-url,
    &.row-max-6 .card-more-link,
		&.row-max-4 .card-more-link,
		&.row-max-3 .card-more-link,
		&.row-max-2 .card-more-link {
			@include span-columns(6);

			width: calc(50% - #{$cards-separation});
			margin-right: $cards-separation;

			&:nth-child(2n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(2n+1) { clear:left; @include card-radious-left(); }
	 	}
	}

	@include media($narrow) {  
	
		&.row-max-1, &.row-max-2, &.row-max-3, &.row-max-4, &.row-max-6, &.row-max-9 {
			 .views-row {
				 margin-right:0;
				 .card {
						margin-right:0;
					}
				 
				 //&.views-row-last { @include card-radious-right(); }
			 }
		}
		
		&.row-max-12 .views-row {
			@include span-columns(1);
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-4 .views-row {
			@include span-columns(3);
			
			width: calc(25% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(4n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(4n+1) { clear:left; @include card-radious-left(); }
		}
		
		&.row-max-3 .views-row {
			@include span-columns(4);
			
			width: calc(33.3% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(3n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(3n+1) { clear:left; @include card-radious-left(); }
		}
			
		&.row-max-2 .views-row {
			@include span-columns(6);

			&:nth-child(2n) { margin-right:0; }
			&:nth-child(2n+1) { clear:left; }

			@include card-radious-all();

			.card-image,
			.card-photo,
			.card-text {
				width: 50% !important;
				float: left;
			}			
		}
		
		&.row-max-1 .views-row {
			@include card-radious-1-card();
		}
		
		
			
		&.row-max-12 .card-more-link,
    &.row-max-12 .card-last-link-url {
			@include span-columns(1);
			
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-6 .card-more-link,
		&.row-max-6 .card-last-link-url {
			@include span-columns(2); 
			
			&:nth-child(6n+1) { clear:left; }
			&:nth-child(6n) { margin-right:0; }
			
			&:nth-child(6n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(6n+1) { clear:left; @include card-radious-left(); }
			
		}

		&.row-max-4 .card-more-link,
		&.row-max-4 .card-last-link-url {
			@include span-columns(3);
			
			width: calc(25% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(4n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(4n+1) { clear:left; @include card-radious-left(); }
		}
		
		&.row-max-3 .card-more-link,
		&.row-max-3 .card-last-link-url {
			@include span-columns(4);
			
			width: calc(33.3% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(3n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(3n+1) { clear:left; @include card-radious-left(); }
		}
			
		&.row-max-2 .card-more-link,
		&.row-max-2 .card-last-link-url {
			@include span-columns(6);
			
			@include card-radious-all();
		}
	}
	
	
	
	//If row-max-6 is selected but the page is under 960px in width, show 3 items per row instead
	@media (min-width:$narrow) and (max-width:959px) {
		&.row-max-6 .views-row {
			@include span-columns(4);
			
			width: calc(33.3% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(3n) { margin-right:0; clear:none; @include card-radious-right(); }
			&:nth-child(3n+1) { clear:left; @include card-radious-left(); }    
		 }
		}
	
	//If row-max-6 is selected but the page has a sidebar, show 3 items per row instead (in content-main and content-additional only)

	@include media ($normal) {
		&.row-max-6 .views-row {
			@include span-columns(2);
			
			width: calc(16.66% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(6n) { margin-right:0; @include card-radious-right(); }
			&:nth-child(6n+1) { clear:left; @include card-radious-left(); }    
		 }
	}

	@media (min-width:500px) and (max-width:$normal) {
		.view:not(.view-team) {
			.card-photo {
				float:left;
			}
		}
	}	
}

@include media ($normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.section-row:not(.view-type-slider) {
				&.row-max-6 .views-row {
					@include span-columns(4);
					&:nth-child(3n) { margin-right:0; }
					&:nth-child(3n+1) { clear:left; }
					&:nth-child(6n) { margin-right:inherit; }
					&:nth-child(6n+1) { clear:none; }    
				}
			}
		}
	}
}

//If row-max-2 is selected and the page is more than 960px in width and DONT have sidebar second, show the image on the left
@media (min-width:$normal) {	
	.sidebar-second main {
		.section-row:not(.view-type-slider) {
			&.row-max-2 .views-row,
			&.row-max-2 .card-more-link,
			&.row-max-2 .card-last-link-url {
				@include span-columns(6);

				width: calc(50% - #{$cards-separation});
				margin-right: $cards-separation;

				&:nth-child(2n) { &, & .views-row { margin-right:0; @include card-radious-right(); }}
				&:nth-child(2n+1) { clear:left; @include card-radious-left(); }
				
				.card-image,
				.card-photo,
				.card-text {
					width: 100% !important;
					float: none;
				}	
			}
		}
	}
}

//If row-max-4 is selected but the page is under 1200px in width and has sidebars, show 2 items per row instead
@media (min-width:$narrow) and (max-width:$wide) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.section-row:not(.view-type-slider) {
				&.row-max-4 .views-row {
				@include span-columns(6);
				&:nth-child(2n) { margin-right:0; clear:none;}
				&:nth-child(2n+1) { clear:left; }    
			 }
			}
		}
	}
}
	
@media (min-width: $narrow) and (max-width:$wide) {
	.row-max-1 article:not(.node-teaser-image) {
		.card-logo,
		.card-image {
			@include span-columns(5); //3
			margin-right: 0;
		}
	}
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 article:not(.node-teaser-image) {
				.card-logo,
				.card-image {	
					@include span-columns(5); //4
					margin-right: 0;
				}
			}
		}
	}
}
@media (min-width:$wide) {
	.row-max-1 article:not(.node-teaser-image) {
		.card-logo,
		.card-image {
			@include span-columns(5); //2
			margin-right: 0;
		}
	}
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 article:not(.node-teaser-image) {
				.card-logo,
				.card-image {
					@include span-columns(5); //3
					margin-right: 0;
				}
			}
		}
	}
}



// /* BLOCK VIEW IN CONTENT BY VIEW-FIELD */ //
.content-section-row-block {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	clear: both;
	padding: 40px 10px;
	
	@media (min-width: $narrow) {
		padding: 40px 20px;
	}
}

// /* BLOCK IN SECTIONS BY CONTEXTS */ //
.postscript-section-row-block {
	margin-left: auto;
	margin-right: auto;
	max-width: 1600px;
	//padding: 40px 10px;
	padding: 0 10px;
	clear: both;
	
	@media (min-width: $narrow) {
		//padding: 40px 20px;
		//padding: 0 20px;
		padding: 40px 20px 0;
	}
}


//.view-card-default-pallete,
.view-card-default-palette {
	.views-row {
		.card { 
			@include palette-default-cards;

			//margin-bottom: 4px;
		}
		a.card:hover {
			@include card-hover;
		}

		.card-feed-link {
			@include palette-default-card-feed-link;
		}
	}

	@include card-hidden-summary-view;
	
	.view-footer {
		@include palette-default-card-feed-link;
	}
}



.views-row-separation,
.views-row-alt-separation {	
	.views-row {
		//float: none !important;
		//display: inline-grid !important;
		float: left !important;
		display: inline-block !important;
		margin-right: 0;
		
		@media (min-width:540px) and (max-width:939px) {

			&:nth-child(2n) { 
				margin-right:0;
			}
			&:nth-child(2n+1) { 
				clear:left; 
			}
		}
		 
		@media (min-width: 940px) {
		
			&:nth-child(4n) { 
				margin-right:0;
			}
			&:nth-child(4n+1) { 
				clear:left;
			}
		}
	}
}

.views-row-separation {
	.views-row {
		margin-right: 0px;
		
		@media (min-width:540px) and (max-width:939px) {
				margin-right: 1px !important;
			}

			@media (min-width: 940px) {
				margin-right: 1px !important;
		}
		.card {
			margin-bottom: $cards-separation;
		}	
	}
}

.views-row-alt-separation {
	.views-row {
		width: 100%;
		
		@media (min-width:540px) and (max-width:939px) {
				// width: calc(50% - #{$cards-separation-alt}/2 - 3px);
				width: calc(50% - #{$cards-separation-alt}/2);
				margin-right: $cards-separation-alt;
			}

			@media (min-width: 940px) {
				// width: calc(25% - #{$cards-separation-alt}/1.3 - 3px);
				width: calc(25% - #{$cards-separation-alt}/1.3);
				margin-right: $cards-separation-alt;
		}
		.card {
			margin-bottom: calc(#{$cards-separation-alt} - 8px);
		}	
	}
}