@media (min-width:$wide) {
	.no-sidebars .node-content .card-summary,
	.no-sidebars .node-content .card-intro,
	.no-sidebars .node-content .card-body,
	.no-sidebars .node-content .card-authors {
		//padding:0 16% 0 0;
	}
}
.node-leaf {
  main {
    background-color: $main-page-bg;
  }
  #content {
    padding: $vert-space;
    background-color: white;
		overflow:hidden;
    
    .card-title-field h1 {
      font-size: 2rem;
      font-weight: normal;
    }
    .card-intro {
      margin-top: $vert-space;
    }
    .card-image {
      float: left;
      margin: 0 $vert-space $horz-space 0;
      
      @media(min-width:640px){
        max-width:55%;
      }
    }
  }
}

main section#content {
  .content-main,
	.content-additional {
    padding: 20px;
    @include media(880px) {
      padding: 30px;
    }
  }
	.content-main,
	.content-additional {
  	background-color: white;
	}
	.content-main + .content-additional {
		margin-top: $vert-space*1.5;
	}
}
.main-content-bg-grey main section#content {
  .content-additional {
  	background-color: transparent;
    padding:0;
	} 
}
.node-content {
	h1 {		
		text-align: left;
		body.node-type-page.menu-depth-1 & {
			text-align:center;
		}
		.card-title-field {
			//font-size:2rem;
			line-height:1.1875;
			//color:$primary;
			font-family:$title-font-family;
			margin:0 0 0;
			text-transform:none;
		}
	}
	.card-image {
		margin-bottom:$vert-space;
	}
	.card-display-date {
		font-size:0.875rem;
		margin-right:3px;
		padding:0;
	}
	.card-location {
    display: inline-block;
    font-size: 0.8125em;
	}
	.card-date,
	.card-display-date,
	.card-location {
		margin-bottom:$vert-space*0.75;
	}
	.card-job-title {
		color:$primary;
		font-size:1.222rem;
		margin-bottom:$vert-space;
		margin-top: -10px;
	}
  .card-summary {
    color:$primary;
    font-size:1.222em;
    font-weight:bold;
    line-height:1.3;
  }
	&.node-publication {
		.card-journal,
		.card-year,
		.card-volume,
		.card-pages,
		.card-doi,
		.card-pmid {
			display:inline-block;
			
			.label {
				display:inline;
			}
		}
		.card-authors,
		.card-abbr-authors {
			font-style:italic;
			margin-bottom:$vert-space/4;
		}
		.card-journal:after {
			content:", ";
		}
		.card-year:after {
			content:"; ";
		}
		.card-volume:after {
			content:": ";
		}
		.card-journal,
		.card-year {
			font-weight:bold;
		}
		.card-body {
			margin-top:$vert-space;
		}
	}
	&.node-organisation {
		.card-logo {
			text-align: center;
			background-color: transparent;
			margin-bottom:1rem;
			
			@media(min-width:$narrow) {
				float:right;
				margin-left:1.25rem;
				max-width:calc(50% - 0.625rem);
			}
		}
	}		
}

.content-row {
	background: $white;
	border: $side-row-border;
	border-radius: $side-row-border-radius;
	box-shadow: $side-row-box-shadow;
	margin: $side-row-margin;
	padding:$side-row-padding;
	overflow: hidden;

	h2 {
		font:$side-row-title-font;
		color:$side-row-title-color;
		margin-left: -20px;
		margin-top: -20px;
		padding: 20px;
		margin-bottom: 20px;
		width: calc(100% + 40px);
		background-color: $side-row-title-bg;
	}
}

.alert-icon {
	display:block;
	background:$alert-blue no-repeat scroll 100% 100% / 25px 25px ;
	height:25px;
	width:25px;
	
	text-indent:-9999px;
	float: left;
	margin:5px 15px 0 10px
}

.view-display-id-alert .body-text {
	overflow:hidden;
	p {
		font-size:22px;
	}
}

.group-landing-page {
	.attachment-after {
		clear:both;
	}
}

.view-id-group_curated,
.view-id-group_related {
	.view-footer {
		float:right;
	}
}
.content-transparent-bg main section#content .content-main {
	background-color:transparent;
	padding:0;
}

.side-attached-block .view-display-id-attachment {
    border-top: $tertiary-border;
    margin-top: 20px;
    padding-top: 20px;
}

.card-body-expand {
  .card-body p {
    padding-bottom:20px;
  }
}

.body-expand {
	margin-bottom: 30px;
}
a.morelink {
  float: right;
  font: $button-font;
  margin-top: 15px;
  &:after {
    background: $ico-expand 50% 50%/22px auto;
    display:inline-block;
    height:22px;
    width:22px;
    content:" ";
    margin-left:10px;
    vertical-align:bottom;
  }
  &:hover:after {
      background: $ico-expand-purple 50% 50%/22px auto;
    }
  &.less {
    &:after {
      background:$ico-collapse 50% 50%/22px auto;
    }
    &:hover:after {
      background: $ico-collapse-purple 50% 50%/22px auto;
    }
  }
}