 main {
	 #sidebar-second {
		background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row,
 	.sidebar-navigation {
		
		background: $side-row-bg;
		border: $side-row-border;
		border-radius: $side-row-border-radius;
		box-shadow: $side-row-box-shadow;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;

		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;

		&.section-with-slider {
		 padding:0;
		}

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
			
			.side-row-wrapper {
				padding-top: $vert-space;
			}
		}

		h2 {
			font:$side-row-title-font;
			color:$side-row-title-color;
			margin-left: -20px;
			margin-top: -20px;
			padding: 20px;
			margin-bottom: 1px;
			width: calc(100% + 40px);
    	background-color: $side-row-title-bg;
			
			.card-title {
				font-size: inherit;
			}
		}

		.card {
			@include palette-sidebar-cards;

			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			.card-text {
				padding: $card-text-padding;
			}
		}
		
		@include card-radious-1-card;
		
		.views-row.views-row-last {
			.card {
				margin-bottom: 0;
			}
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-sidebar-card-more-link;
			float: right;
			a {
				padding: 5px 10px;
				border-radius: $side-row-border-radius;
				margin-top: 0.5em;
			}
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}
				
		
		&.view-person-connect {
			padding: 0;
			background: transparent;
			
			h2 {
				margin-left: 0;
				margin-top: 0;
				width: 100%;
			}
		}
		
		// /* RELATED CONTENT */ //
		.view-related .views-row,
		.view-tagged-events .views-row,
    .view-tagged .views-row,
    .view-tagged-resources {
			margin-bottom: $vert-space;
			
			.card-display-date {
				padding: 0;
			}
			.card-date {
				padding: 0 0 0 $horz-space/2;
				margin-top: 6px;
        float:right;
        font-size:0.888rem;
				
				@media (min-width:$narrow) and (max-width:$normal) {
					padding: 0 0 $vert-space/2;
				}
				@media (min-width:$normal) and (max-width:1250px) {
					float: none;
					clear: both;
					padding-left: 0;
				}	
			}
      .card-resource-type,
      .card-event-type {
        padding:0;
        margin:0;
        font-size:0.888rem;
      }
      .card-event-type {
      	display: inline;
      }
			.card-type {
				padding-bottom: $vert-space/2;
				margin: 7px 5px 0 0;
				padding: 0 0 $vert-space/2;
				 font-size:0.888rem;
			}
			
			h3 {
        font:$side-row-card-title-font;
        clear: both;
        a {
        color: $imperial;	
          &:hover {
            color:$dingy_dungeon;
          }
        }
      }
		}
		
		.view-tagged-events .views-row {
			.card-display-date {
				margin-top: 3px;
				display: inline;
				float: right;
			}
		}
		
		// /* SEPARATION LINE */ //
		
		& > .view:not(.view-display-id-connect) {
			padding-top: $vert-space;
			
			.views-row:not(.views-row-last) {
				padding-bottom: $vert-space;
				border-bottom: $tertiary-border;
				margin-bottom: $vert-space;
			}
		}
		
		& .card-select-feed .view-tagged-resources {
			//padding-top: $vert-space;
			
			.attachment  {
				padding-top: $vert-space;
				border-top: $tertiary-border;
				margin-top: $vert-space;
			}
		}
		.view-id-event.view-display-id-sponsors {
			.item-list li {
				margin-bottom: 0.5em;
			}
		}
		&.logged-in-join-block {
			a.button {
				margin-bottom: 0;
			}
		}
    
    &.side-logo {
      background: transparent;
      padding: 0;
      
      & > .view {
        padding: 0;
      }
    }
	}

	// /* SIDEBAR NAVIGATION - RESOURCES */ //
	#sidebar-second .inner-wrapper-sticky {
		@media (max-width:$normal) {
			position: relative !important;
			width: auto !important;
			top: inherit;
		}
		
		.block.sidebar-navigation {
			display: none;
			
			@media (min-width:$normal) {
				display: block;
				padding-left: 0;
				padding-right: 0;
				h2 {
					margin-left: 0;
					background-color: $azureish_white2;
				}
				ul#sidebar-nav-links {
					list-style: none;
					padding: 20px 0 0;
					margin: 0;

					li a {
						border-left: 3px solid transparent;
						padding: 12px 20px 12px 17px;
						color: $action-alt-default;
						font: 1.111em/1 $title-font-family;
						display: block;

						&:hover {
							background-color: white;
							color: $green_blue;
						}

						&.active {
							border-left: 3px solid $bleu_de_france;
							background-color: white;
							color: $green_blue;
						}
					}
				}
			}
		}
	}
} 
