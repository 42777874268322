.section-row.section-with-gallery {
	.card-photos {
		.slick-slider {
			//background-color:$primary;
			overflow:hidden;
			position:relative;
			.slick-slide {
				//margin:0 10px;
				//opacity:0.25;
				//transition:opacity 0.25s ease-in-out;
				&:focus {
					outline:none;
				}
				&.slick-current {
					//opacity:1;
				}
				img {
					overflow:hidden;
					border-radius:0.5rem;
				}
				.caption p {
					background:none;
					font-size:1.125rem;
					.caption-wrapper {
						padding:16px 10px;
						margin-bottom:0;
					}
				}
			}
		}
		.slick-controls {
			position:inherit;
			display:block;
			width:auto;
			height:auto;
			button.slick-arrow {
				border-radius:50%;
				padding:1.25rem;
				top:50%;
				transform:translate(0,-50%);
				&.slick-prev {
					left:1.25rem;
					background:$slick-alt-1-prev-bg;
					&:hover {
						background:$slick-alt-1-prev-hover-bg;
					}
				}
				&.slick-next {
					right:1.25rem;
					background:$slick-alt-1-next-bg;
					&:hover {
						background:$slick-alt-1-next-hover-bg;
					}
				}
			}
		}
	}
}

.pswp__caption__center {
	max-width:45rem;
	text-align:center;
}
.pswp__button--share { display:none; }
