// NEW EXTERNAL EVENT FORM //
.logged-in:not(.role-administrator):not(.role-editor):not(.role-web-admin) {
	.node-external_event-form,
	.node-external_course-form,
	.node-external_jobs-form  {
		.card-content-group,
		.field-name-field-full-title,
		.field-name-field-display-date,
		.form-item-field-start-date-und-0-value-time,
		.form-item-field-end-date-und-0-value-time {
			display:none;
		}
	}
}
.page-user-register .user-info-from-cookie #edit-civicrm-profile-register .crm-container {
	fieldset > legend {
		display:none;
	}
}

.logged-in {
	.node-external_event-form,
	.node-external_course-form,
	.node-external_jobs-form {
		.form-item-language { 
			display:none;
		}

		.vertical-tabs {
			margin: 0;
			border: none;

			ul.vertical-tabs-list {
				display: none;
			}

			.vertical-tabs-panes {
				#edit-field-event-type,
				#edit-field-processing-status,
				#edit-field-publish-status {
					display: none;
				}

				.field-type-datetime {
					.date-combo {
						margin: 1rem 0;
						padding: 0;

						legend {
							font-weight: 600;
							margin-bottom: 5px;
						}
					}
				}

				#edit-field-promoting-event-cost {
					padding: 20px;
					background-color: $isabelline;
				}

				.group-card {
					margin-top: 40px;
					padding: 0;	

					& > legend {
						font: $card-title-font; 
					}

					.form-managed-file .form-file,
					.form-managed-file .form-submit {
						margin: 10px 0;
					}
				}
			}
		}
	}
}