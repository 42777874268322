.node-type-blog {
	// main section#content .content-main {
	// 	padding-bottom: 0;
	// }

	.node-blog > .view-sections {
		padding-bottom: 30px;
	}
}

// /* COMMENTS */ //

div#comments {
	padding: 0;
	margin: 20px -20px -20px;
	
	@media screen and (min-width: 880px) {
		margin: 30px -30px -30px;
	}
	
	border-top: 30px solid $snow;
	
	h2.title:not(.comment-form) {
		display: block;
		padding: 20px 30px;
		width: 100%;
		font: 300 1.556em/1 $title-font-family;
		color: $green_blue;
		background: $ico-expand-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
		border: 1px solid $azureish_white;
		margin-bottom: 0;
		cursor:pointer;
	}
	
	h3 {
		font: 300 1.556em/1 $title-font-family;
	}	
	
	.indented > .indented {
    margin-left: 0;
	}

	.comment {
		padding: 0 30px;
		background-color: white;

		visibility: hidden;
		height: 0px;
		transition: all 300ms linear 0s;
		overflow: hidden;
		
		h3,
		a.permalink {
			display: none;
		}
		.links  {
			list-style: none;
   	 	padding: 0;
			
			li {
				display: inline-block;
				margin-right: 10px;
				
				a {
					font-size: 0.889rem;
    			padding: 8px 10px;
				}
			}
		}
	} 

	&.visible {	
		h2.title:not(.comment-form) {
			background: $ico-collapse-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
		}

		.comment {
			padding: 30px 30px 0px;
			visibility: visible;
			height: auto;
		}
		h2.title {
			display: block;
		}
		form {
			visibility: visible;
			height: auto;
			padding: 0 30px 30px;
		}
		h2.title.comment-form {
	    margin: 0;
	    padding: 30px;
	    visibility: visible;
			height: auto;
	    // border-top: 30px solid $snow;
		}
		
	}	
	
	h2.title.comment-form {
		visibility: hidden;
		height: 0px;
		padding: 0;
		margin: 0;
	}

	form {
		display: block;
		visibility: hidden;
		height: 0px;

		.form-item {
			margin: 0;
		}
		
		.field-name-field-comment {
    	width: calc(100% - 1px);
		}
		
		.form-actions {
			margin-top: 1em;
			margin-bottom: 1em;
			text-align: right;
			
			input,
			a.edit-cancel {
				margin-left: 20px;
			}
		}
	}
}
