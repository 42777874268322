.event-header {
	.card-location {
		background-position: 0px 7px;
	}
	
	& + .event-description {
		margin: 20px -20px -20px;
		width: calc(100% + 40px);

		@media (min-width: $narrow) {
			margin: 30px -30px -30px;
			width: calc(100% + 60px);
		}	
		.card-description-wrapper,
		.card-body-wrapper {
			padding: 20px 20px 0;
			border-top: 30px solid $snow;

			@media (min-width: $narrow) {			
				padding: 30px; 
			}

			.card-description *:last-child,
			.card-body *:last-child {
				margin-bottom: 0;
			}
		}
	}
} 

// EVENTS //
.node-type-event {
	.view-sections {
		margin-top: 30px;
	}
	.has-coverage {
		margin: -30px -30px 30px;
		padding: 5px 30px;
		background: #FBEFF3;
		color: $dingy_dungeon;
	}
	.event-header {
		.card-date-wrapper {
			margin-right: 0;
			.card-display-date {
				margin-bottom: 0;
			}
		}
	}
	.social-programme {
		margin-bottom: 2em; 
	}
	.event-summary-wrapper {
		margin-bottom: 1em;
	}
	.view-id-links.view-display-id-block .card-text.card-with-summary {
		width: 100% !important;
	}
}

.page-events:not(.page-events-external-events):not(.page-events-external-courses) section#content {
	h1#page-title {
		display: none;
	}
	.view-id-events_page.view-display-id-page,
	.view-id-events_page.view-display-id-page_1 {
		.view-header {
			padding: 0;
			h1.page-title {
				display: inline-block;
				padding-right: 20px;
			}
			.past-events {
				display: inline-block;
				margin-left: 1em;
			}
		}
	}
}

.view-id-event_fee_and_sessions.view-display-id-page {
	margin-top: 30px;
	.view-content {
		&:before {
			display: none;
		}
		h3 {
			margin-top: 0;
			border-top: 1px solid $isabelline;
			width: calc(100% + 60px);
			margin-left: -30px;
			padding: 30px;
			background-position: right 30px center;
			&:hover,
			&.active,
			&.active:hover {
				background-position: right 30px center;
			}
		}
		.views-row {
			display: none;
			padding: 15px 0 0;
			&.views-row-last {
				padding-bottom: 15px;
			}
		}
	}
}

.view-id-my_events.view-display-id-my_events {
	.views-row {
		width: 100%;
		.event-card {
			border-radius: 0;
			.card-text {
				width: 100% !important;
				.card-event-type {
					padding: 0;
					background-color: transparent;
					margin: 10px 0;
				}
				.card-title a {
					color: $imperial;
					&:hover {
						color: $dingy_dungeon;
					}
				}
				.has-coverage {
					text-transform: initial;
					padding: 0;
					margin: 0;
				}
				.button {
					margin: 10px 0;
					color: white;
					@include media($narrow) {
						position: absolute;
						bottom: 50px;
						right: 20px;
					}
				}
				.card-city {
					float: left;
					clear: both;
					margin: 6px 0 5px;
				}
				.card-cpd-hrs {
					position: absolute;
					top: 10px;
					right: 20px;
					padding: 3px 8px;
					font-size: 0.778rem;
					font-weight: 600;
					border: 1px solid $pastel_gray;
					border-radius: 6px;
					line-height: 1;
				}
				.card-certificate {
					float: left;
					clear: both;
					text-decoration: underline;
				}
				.card-views-conditional,
				.card-views-conditional-2 {
					@media (max-width: 720px) {
						float: left;
						clear: both;
					}
				}
				.card-footer {
					@media(max-width:540px) {
				    display: block;
				    margin: 0 -20px -20px;
				    width: calc(100% + 40px);
				    clear: both;
					}
					width: 100%;
					bottom: 0;
					left: 0;
					.upcoming {
						background: $glitter;
						color: $green_blue;
						padding: 4px 20px;
						line-height: 1.4;
					}
					.past {
						background: $platinum;
						color: $maximum_purple;
						padding: 4px 20px;
						line-height: 1.4;
					}
				}
			}
		}
	}
	.view-display-id-event_footer,
	.view-display-id-event_text {
		.views-row {
			width: 100% !important;
		}
	}
}