.wysiwyg-intro-text {
	color:$secondary;
	font: 1.25em/1.3 $title-font-family;
}
.smaller-text {
	font-size: 0.750rem;
	line-height: 1.5;
}
.larger-text {
	font-size: 1.250rem;
	line-height: 1.3;
}
.alignright {
  float: right;
  margin-top: 0.4em;
  margin-bottom: 1.625em;
  margin-left: 1.625em;
}
.alignleft {
  float: left;
  margin-top: 0.4em;
  margin-bottom: 1.625em;
  margin-right: 1.625em;
}