.view-external-jobs.view-id-external_jobs {
  &.search-page-content-type-tpl .view-filters .views-exposed-form {
    display: block;
  }
}

// /* GOLD SILVER JOBS */ //
.silver-ext-job-tag { display: none; }
.card.gold-ext-job .card-text {
   border-color: #FDD723 ;
}
.gold-ext-job-tag {
   //background: #FDD723 ;
   display: block;
   float: right;
   //border-radius: 0 0 2px 2px;
   //text-shadow: 1px 1px 1px rgba(255,255,255,0.5);
   //color: #8B7614 ;
   width: 20px;
   height: 52px;
   
   background: $ico-gold-flag 50% 0 / 20px 52px transparent no-repeat;
   
   & > * {
     display: none;
   }
}
.card .gold-ext-job-tag {
   //font-size: 13px;
   //padding: 2px 6px;
   margin: -21px -10px 0 20px;
}
.node-type-external-jobs .content-main {
  position: relative;
  
  .external-jobs-header {
    .ext-job-row {
      // padding: 0;
      // width: calc(100% + 40px);
      // margin: -20px 0 20px -20px;
      // border-top: 4px solid #FDD723;
      // height: 30px;
      
      padding: 0;
      border-top: 4px solid #FDD723;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;      

      // @include media(880px) {
      //   width: calc(100% + 60px);
      //   margin: -30px 0 20px -30px;
      // }
      
      .gold-ext-job-tag {
      //   font-size: 16px;
      //   padding: 3px 10px;
        margin-right: 20px;
      // 
      //   @include media(880px) {
      //     margin-right: 30px;
      //   }
      }
    }
    
    .ext-job-row + h1 {
      margin-right: 40px;
    }
  }
}