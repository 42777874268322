.node-type-person {
	
	.node-inner {
		overflow: hidden;
	}
	
	.node-person.person-with-image {
    min-height: 410px;
	}
	
	.card-suffix {
		margin: -10px 0 10px;	
	}
	
	.card-profile-details {
		margin-bottom: $vert-space;
		text-align: center;
		.card-photo img {
			max-width: 360px;
			width: 100%;
		}		
		@include media($narrow) {
			float: right;
			max-width: 310px;
			margin-left: $horz-space*2;
			display: block;
			width: 33%;
			margin-bottom: 20px;
		}
	}

	.card-profile-details-mobile {
		display: block;	
		@include media($narrow) {
			display: none;
		}
	}
	
	
	.view-person-connect {
		border-radius: 6px 6px 0 0;
		
		.view-content {
			display: flex;
    	flex-flow: row wrap;
			
			.views-row {
				width: calc(100% / 4);
				padding-right: 1px;
    		margin-bottom: 1px;
				
				@include media($normal) {
					width: calc(100% / 5);
				}
			}
		}
	
		.card-links ul li,
		.card-links {
			a /* Unidentified */ {
				background:$ico-web-color no-repeat 50% 50% / 16px 16px $view-person-connect-bg;
				color:$action-default;
				display: block;
				height: 60px;
				overflow: hidden;
				text-indent: -9999px;
				
				background-size: 20px 20px !important;

				 &:hover {
					 background: $ico-web-light no-repeat 50% 50% / 16px 16px $view-person-connect-hover;
					 color:$action-hover;
				}
			}
		}

		.card-links /* Institutional profile */ {
			.institution a,
			a[href*=".ac.uk"],
			a[href*=".edu"] {
				background: $ico-institution-color no-repeat 50% 50% / 15px 15px $view-person-connect-bg;

					&:hover {
						background: $ico-institution-light no-repeat 50% 50% / 15px 15px $view-person-connect-hover;
					}
			}
		}

		.card-links /* Research Gate */ {
			.research-gate a,
			a[href*="researchgate.net"],
			a[href^="https://www.researchgate.net"],
			a[href^="http://www.researchgate.net"],
			a[href^="https://researchgate.net"],
			a[href^="http://researchgate.net"] {
						background: $ico-researchgate-color no-repeat 50% 50% / 16px 17px $view-person-connect-bg;

					&:hover {
						background: $ico-researchgate-light no-repeat 50% 50% / 16px 17px $view-person-connect-hover;
					}
			}
		}

		.card-links /* Email */ {
			.email a,
			a[href^="mailto:"],
			a[href*="@"] {
				background: $ico-newsletter-color no-repeat 50% 50% / 14px 15px $view-person-connect-bg;

				&:hover { 
					background: $ico-newsletter-light no-repeat 50% 50% / 14px 15px $view-person-connect-hover;
				} 
			}
		}

		.card-links /* Google Scholar */ {
			.g-scholar a,
			a[href*="scholar.google"] {
			background: $ico-gscholar-color no-repeat 50% 50% / 15px 17px $view-person-connect-bg;

				&:hover {
					background: $ico-gscholar-light no-repeat 50% 50% / 15px 17px $view-person-connect-hover;
				}
			}
		}

		.card-links /* linkedin */ {
			.linkedin a,
			a[href*="linkedin.com"],
			a[href^="https://www.linkedin.com"],
			a[href^="http://www.linkedin.com"],
			a[href^="https://linkedin.com"],
			a[href^="http://linkedin.com"] {
				background: $ico-linkedin-color no-repeat scroll 50% 50% / 17px 14px $view-person-connect-bg;

				&:hover {
					background:$ico-linkedin-light no-repeat scroll 50% 50% / 17px 14px $view-person-connect-hover;
				}
			}  
		}

		.card-links /* twitter */ {
			.twitter a,
			a[href*="twitter.com"],
			a[href^="https://www.twitter.com"],
			a[href^="http://www.twitter.com"],
			a[href^="https://twitter.com"],
			a[href^="http://twitter.com"] {
				background: $ico-twitter-color no-repeat scroll 50% 50% / 17px 17px $view-person-connect-bg;

				&:hover {
					background: $ico-twitter-light no-repeat scroll 50% 50% / 17px 17px $view-person-connect-hover;
				}
			}
		}

		.card-links /* facebook */ {
			.facebook a,
			a[href*="facebook.com"],
			a[href^="https://www.facebook.com"],
			a[href^="http://www.facebook.com"],
			a[href^="https://facebook.com"],
			a[href^="http://facebook.com"] {
				background: $ico-facebook-color no-repeat scroll 50% 50% / auto 17px $view-person-connect-bg;

				&:hover {
					background: $ico-facebook-light no-repeat scroll 50% 50% / auto 17px $view-person-connect-hover;
				}
			}
		}

		.card-links /* google+ */ {
			.g-plus a,
			a[href*="plus.google.com"],
			a[href^="https://www.plus.google.com"],
			a[href^="http://www.plus.google.com"],
			a[href^="https://plus.google.com"],
			a[href^="http://plus.google.com"] {
				background:$ico-gplus-color no-repeat scroll 50% 50% / 19px 17px $view-person-connect-bg;

				&:hover {
					background: $ico-gplus-light no-repeat scroll 50% 50% / 19px 17px $view-person-connect-hover;
				}
			}
		}

		.card-links /* orcid */ {
			.orcid a,
			a[href*="orcid.com"],
			a[href^="https://www.orcid.com"],
			a[href^="http://www.orcid.com"],
			a[href^="https://orcid.com"],
			a[href^="http://orcid.com"] {
				background:$ico-orcid-color no-repeat scroll 50% 50% / 14px 14px $view-person-connect-bg;

				&:hover {
					background: $ico-orcid-light no-repeat scroll 50% 50% / 14px 14px $view-person-connect-hover;
				}
			}
		}

		.card-links /* YouTube */ {
			.youtube a,
			a[href*="youtube.com"],
			a[href^="https://www.youtube.com"],
			a[href^="http://www.youtube.com"],
			a[href^="https://youtube.com"],
			a[href^="http://youtube.com"] {
				background:$ico-youtube-color no-repeat scroll 50% 50% / 18px auto $view-person-connect-bg;

				&:hover {
					background: $ico-youtube-light no-repeat scroll 50% 50% / 18px auto $view-person-connect-hover;
				}
			}
		}

		.card-links /* flickr */ {
			.youtube a,
			a[href*="flickr.com"],
			a[href^="https://www.flickr.com"],
			a[href^="http://www.flickr.com"],
			a[href^="https://flickr.com"],
			a[href^="http://flickr.com"] {
				background:$ico-flickr-color no-repeat scroll 50% 50% / 18px auto $view-person-connect-bg;

				&:hover {
					background: $ico-flickr-light no-repeat scroll 50% 50% / 18px auto $view-person-connect-hover;
				}
			}		
		}
  }
}




// /* TEAM NUGGET */

.view-team {
	.card-text {
		.card-title-et {
			margin-bottom: 0.333rem;
		}
		.card-job-title {
			font-weight:600;
		}
		.card-role {
			font-size: 0.889rem;
		}
	}
	.card-views-conditional .card-role {
		position: absolute;
		margin-top: 10px;
		padding: 5px 10px;
		background-color: $imperial;
		color: white;	
		font:bold 0.889rem/1 $base-font-family;
		text-transform: uppercase;
		text-align: left;
		z-index: 99;
	}
}