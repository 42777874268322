.soc-accelerators {
	will-change:min-height;
	.soc-accelerators-inner {
		padding-bottom: 8px;
		display:flex;
		transform:translate(0, 0);
		transform:translate3d(0, 0, 0);
		will-change:position,transform;
		.block-content-wrapper {
			@media (min-width:$narrow) {
				margin-right:-20px;
				margin-left:auto;
				margin-top: 25px;
			}
		}
	}
	h2 {		
		font: 600 0.833rem/1 $title-font-family;
		margin: 0;
    padding: 10px;
    text-transform: uppercase;
		background-color: $platinum;
		margin-bottom: 1px;
		
//		@media (min-width:$narrow) {
//    	max-width: 70px;
//		}
	}

	.card-links ul {
		list-style: none;
		margin: 0;
		padding: 0;
		max-width: 70px;
		
		
		@media (max-width:$narrow) {
    	max-width: 100%;
			width: 100%;
			display: flex;
			flex-flow: row wrap;
		}
		
		li {
			margin-bottom: 1px;
			
			@media (max-width:$narrow) {
	    	width: calc(100% / 5);
				padding-right: 1px; 
			}
		}
	}
	
	.card-links ul li,
	.card-links {
		a /* Unidentified */ {
			background:$ico-web-color no-repeat 50% 50% / 16px 16px $view-person-connect-bg;
			color:$action-default;
			display: block;
			height: 60px;
			overflow: hidden;
			text-indent: -9999px;
			background-size: 20px 20px !important;

			 &:hover {
				 background: $ico-web-white no-repeat 50% 50% / 16px 16px $view-person-connect-hover;
				 color:$action-hover;
			 }
		}
	}

	.card-links /* Institutional profile */ {
		.institution a,
		a[href*=".ac.uk"],
		a[href*=".edu"] {
			background: $ico-institution-color no-repeat 50% 50% / 15px 15px $view-person-connect-bg;

				&:hover {
					background: $ico-institution-white no-repeat 50% 50% / 15px 15px $view-person-connect-hover;
				}
		}
	}

	.card-links /* Research Gate */ {
		.research-gate a,
		a[href*="researchgate.net"],
		a[href^="https://www.researchgate.net"],
		a[href^="http://www.researchgate.net"],
		a[href^="https://researchgate.net"],
		a[href^="http://researchgate.net"] {
					background: $ico-researchgate-color no-repeat 50% 50% / 16px 17px $view-person-connect-bg;

				&:hover {
					background: $ico-researchgate-white no-repeat 50% 50% / 16px 17px $view-person-connect-hover;
				}
		}
	}

	.card-links /* Email */ {
		.email a,
		a[href^="mailto:"],
		a[href*="@"] {
			background: $ico-newsletter-color no-repeat 50% 50% / 14px 15px $view-person-connect-bg;

			&:hover {
				background: $ico-newsletter-white no-repeat 50% 50% / 14px 15px $view-person-connect-hover;
			}
		}
	}

	.card-links /* Google Scholar */ {
		.g-scholar a,
		a[href*="scholar.google"] {
		background: $ico-gscholar-color no-repeat 50% 50% / 15px 17px $view-person-connect-bg;

			&:hover {
				background: $ico-gscholar-white no-repeat 50% 50% / 15px 17px $view-person-connect-hover;
			}
		}
	}

	.card-links /* linkedin */ {
		.linkedin a,
		a[href*="linkedin.com"],
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-color no-repeat scroll 50% 50% / 17px 14px $view-person-connect-bg;

			&:hover {
				background:$ico-linkedin-white no-repeat scroll 50% 50% / 17px 14px $view-person-connect-hover;
			}
		}  
	}

	.card-links /* twitter */ {
		.twitter a,
		a[href*="twitter.com"],
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"],
		a[href^="http://twitter.com"] {
			background: $ico-twitter-color no-repeat scroll 50% 50% / 17px 17px $view-person-connect-bg;

			&:hover {
				background: $ico-twitter-white no-repeat scroll 50% 50% / 17px 17px $view-person-connect-hover;
			}
		}
	}

	.card-links /* facebook */ {
		.facebook a,
		a[href*="facebook.com"],
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-color no-repeat scroll 50% 50% / auto 17px $view-person-connect-bg;

			&:hover {
				background: $ico-facebook-white no-repeat scroll 50% 50% / auto 17px $view-person-connect-hover;
			}
		}
	}

	.card-links /* google+ */ {
		.g-plus a,
		a[href*="plus.google.com"],
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-color no-repeat scroll 50% 50% / 19px 17px $view-person-connect-bg;

			&:hover {
				background: $ico-gplus-white no-repeat scroll 50% 50% / 19px 17px $view-person-connect-hover;
			}
		}
	}

	.card-links /* orcid */ {
		.orcid a,
		a[href*="orcid.com"],
		a[href^="https://www.orcid.com"],
		a[href^="http://www.orcid.com"],
		a[href^="https://orcid.com"],
		a[href^="http://orcid.com"] {
			background:$ico-orcid-color no-repeat scroll 50% 50% / 14px 14px $view-person-connect-bg;

			&:hover {
				background: $ico-orcid-white no-repeat scroll 50% 50% / 14px 14px $view-person-connect-hover;
			}
		}
	}

	.card-links /* YouTube */ {
		.youtube a,
		a[href*="youtube.com"],
		a[href^="https://www.youtube.com"],
		a[href^="http://www.youtube.com"],
		a[href^="https://youtube.com"],
		a[href^="http://youtube.com"] {
			background:$ico-youtube-color no-repeat scroll 50% 50% / 18px auto $view-person-connect-bg;

			&:hover {
				background: $ico-youtube-white no-repeat scroll 50% 50% / 18px auto $view-person-connect-hover;
			}
		}
	}

	.card-links /* flickr */ {
		.flickr a,
		a[href*="flickr.com"],
		a[href^="https://www.flickr.com"],
		a[href^="http://www.flickr.com"],
		a[href^="https://flickr.com"],
		a[href^="http://flickr.com"] {
			background:$ico-flickr-color no-repeat scroll 50% 50% / 18px auto $view-person-connect-bg;

			&:hover {
				background: $ico-flickr-white no-repeat scroll 50% 50% / 18px auto $view-person-connect-hover;
			}
		}		
	}

	.card-links /* flickr */ {
		.calendar {
			width: auto;
			
			.card-start-date {
				margin: 0;
				padding: 0;
				
				.label,
				.date-display-single {
					display: none;
				}	
				
				.addtocalendar {
					width: 100%;

					a.atcb-link {
						background:$ico-calendar-color no-repeat scroll 50% 50% / 18px auto $view-person-connect-bg;
						box-shadow: none;
						border-radius: 0;
						margin: 0;

						&:hover {
							background: $ico-calendar-white no-repeat scroll 50% 50% / 18px auto $view-person-connect-hover;
						}
					}

					.atcb-list {
						max-width: none;
						margin-top: 1px;
						background: $main-page-bg;
						box-shadow: none;
						border: none;
						border-radius: 0;

						*:last-child {
							margin-bottom: 0;
						}

						a,
						a:hover {
							background-image: none;
							text-indent: 0;
							width: auto;
							max-width: 100%;
							height: auto;
							padding: 10px;
						}

						a:hover {
							color: white;
						}
					}
				}
			}
		}		
	}
}