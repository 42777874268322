@media (max-width:879px) {
	#site-header .block-menu-block .menu-block-wrapper ul.menu.sm > li.menu-mlid-3706 {
		& > ul.menu > li > a{
			height: auto !important;
			max-height: none !important;
		}
	}
}

@include media(880px) {
	
	#site-header .block-menu-block .menu-block-wrapper ul.menu.sm > li.menu-mlid-3706.low-taxonomy-menu {
		& > ul.menu {
			margin-left: 0px !important;
			
			& > li,
			& > li > a {
				height: auto !important;
			}
		}
	}

	#site-header .block-menu-block .menu-block-wrapper ul.menu.sm > li.menu-mlid-3706:not(.low-taxonomy-menu) {

		position: initial;
		
		& > a {
			//pointer-events: none;
			cursor: default;
			text-decoration: none;
		}

		& > a.has-submenu + ul.menu {
//			display: flex !important;
			width: auto !important;
//			display: flex;
			align-items: stretch;
			flex-flow: row nowrap;
			opacity: 1 !important;

			width: auto;
			top: auto; 
			right: 0px;
			left: auto !important;
			max-width: 1560px !important; 
			width: calc(100vw - 60px) !important;
			text-align: left;
			margin: 0 -40px 0 20px !important;
			padding: 0 !important;
			//height: 850px;
			z-index: 999;

			& > li {
//				display: inline;
				position: relative;
				border-left: 1px solid $platinum;
				width: 20%;
				overflow: hidden;
				background-color: $isabelline;
				
				display: inline-block;
				margin: 0 0 -6px -4px;
				
				&.first {
					width: calc(20% + 1px);
    			margin: 0 0 -6px 0;
				}
				&.first {
					border: none;
				}

				a.column-title,
				a.column-title:hover {
					background-color: $isabelline;
					color: $dark_purple;
					font: 300 1.111rem/1 $title-font-family;
					font-style: italic;
					line-height: 1.225;
					padding: 0.625rem 0.875rem;
					//border-bottom: 2px solid $platinum !important;

					pointer-events: none;
					cursor: default;
					text-decoration: none;
				}

				li.last {
					border: none;
				}
			}

			ul.menu {
				display: block !important;
				top: 0;
				position: relative; 
				margin: 0 !important;
				left: 0;
				max-width: 100%;
				width: 100%;
				box-shadow: none;
				height: 100% !important;	
			}

			a {
				white-space: normal !important;
				color: $davys_grey;
				padding: 0.333rem 0.875rem;

				&:hover,
				&.active {
					color: white;
				}			
			}

			.sub-arrow {
				display: none;
			}
		}
	}
}