.main-menu-btn {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-indent: 28px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
/* hamburger icon */
.main-menu-btn-icon, .main-menu-btn-icon:before, .main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 2px;
  height: 2px;
  width: 24px;
  background: #bbb;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.main-menu-btn-icon:before {
  content: '';
  top: -7px;
  left: 0;
}
.main-menu-btn-icon:after {
  content: '';
  top: 7px;
  left: 0;
}
/* x icon */
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
  height: 0;
  background: transparent;
}
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* hide menu state checkbox (keep it visible to screen readers) */
#main-menu-state {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px,1px,1px,1px);
}
/* hide the menu in mobile view */
#main-menu-state:not(:checked) ~ ul.menu {
  display: none;
}
#main-menu-state:checked ~ ul.menu {
  display: block;
}
@media (min-width: 880px) {
  /* hide the button in desktop view */
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
  /* always show the menu in desktop view */
  #main-menu-state:not(:checked) ~ ul.menu {
    display: block;
  }
}
