.search-page-content-type,
.search-page-content-type-tpl {
	&.list {
		.views-row {
			float: none;
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			
			@media (min-width: $narrow) {
				.card {
					display: flex;
					
					.card-image {
						//float: right;
						//width: 50%;
						width: 360px;
						max-width: 50%;
						
						.card-image-wrapper.card-image-empty {
							height: 100%;
						}
					}
					.card-text {
						width: 100%;
					}
				}
			}
		} 
	}		
}

.search-page-content-type-tpl {
	.view-blog,
	.view-external-events,
	.view-external-courses,
	.view-external-jobs {
		.view-footer {
			a.button, a.spamspan {
				float: right;
				margin-top: 20px;
			}
		}
	}
}

// /*  RESOURCES TAGS */ //
.card-resource-type,
.card-content-members-only,
.card-resource-series,
.card-type,
.card-event-type {
	font: 600 0.778rem/1 $base-font-family;
	padding: 5px 10px;
	color: $primary;
	background-color: $isabelline;
	text-transform: uppercase;
//	float: left;
//	margin: 10px 10px 0 0;
//	display: block;
	
	float: none;
	margin: 10px 5px 0 0;
	display: inline-block;
} 

.card-content-members-only {
	border-radius: 0 12px 12px 0;
	padding-right: 34px;
	background: $ico-lock 100% 0 / 24px auto no-repeat $isabelline; 
}

.card-resource-series {
	color: $green_blue;
	background-color: $glitter;
}
