.node-type-resource {
	section#content {
		.content-main {
			padding: 20px 0;

			.resource-header {
				padding: 0 20px 20px;
			}
			
			.topbar-navigation.side-row {
				display: block;
				padding: 0;
				background-color: $snow;
				border-top: 30px solid $snow;
				margin: 0;

				h2 {
					border-radius: 6px 6px 0 0;
					width: 100%;
					margin: 0;
					background-color: $azureish_white2;
				}
				.topbar-navigation-inner {
					padding: 20px 0;
					background-color: $isabelline;

					ul#topbar-nav-links {
						list-style: none;
						padding: 0;
						margin: 0;

						li a {
							border-left: 3px solid transparent;
							padding: 12px 20px 12px 17px;
							color: $action-alt-default;
							font: 1.111em/1 $title-font-family;
							display: block;

							&:hover {
								background-color: white;
								color: $green_blue;
							}

							&.active {
								border-left: 3px solid $bleu_de_france;
								background-color: white;
								color: $green_blue;
							}
						}
					}
				}
					
				@media (min-width:$normal) {
					display: none;
				}
			}
			
			.view-sections .section-inner {
				padding: 20px 20px 0;
				border-top: 30px solid $snow;
				margin-top: 20px;
			}
			
			.resource-header + .card-body,
			.side-row + .card-body {
				padding: 20px 20px 0;
			}

			@include media(880px) {
				padding: 30px 0;
				.resource-header {
					padding: 0 30px 20px;
				}
				.resource-header + .card-body,
				.side-row + .card-body {
					padding: 30px 30px 0;
				}
				
				.view-sections .section-inner {
					margin-top: 30px;					
					padding: 30px 30px 0;
				}
			}
			//.block.block-webform,
			#block-webform-client-block-250 {
				display:none;
			}
		}
		.content-additional {
			.view-display-id-additional {
				padding: 30px;
			}
			.attachment-title .card-title {
				text-align: left;
			}
		}
	} 
	
	.node-header {
		.resource-header-details {
			position: relative;
			.card-resource-type-i {
				.card-resource-type {
					margin: 0;
					border-radius: 0 12px 12px 0;
					padding-right: 34px;
					display: block;
				}

				.resource-type-i-icon {
					position: absolute;
					right: 0;
					top: 0;
					background: $ico-info 100% 0/24px auto no-repeat transparent;
					text-indent: -9999px;
					height: 24px;
					width: 24px;
					cursor: pointer;
				}

				.resource-type-info {
					display: none;
				}

				.resource-type-i-icon:hover + .resource-type-info {
					display: block;
					position: absolute;
					top:30px;
					right: 20px;
				}
			}

			.card-resource-type-i,
			.card-content-members-only {
				float: left;
				margin: 0 10px 30px 0;
				position: relative;
			}
		
			.resource-type-info {
				display: none;
				position: absolute;
				top: 38px;
				width: auto;
				max-width: calc(100% - 80px);
				left: 40px;
				font-size: 0.889rem;
				 
//				.resource-type-info-arrow {
//					width: 20px;
//					height: 14px;
//   				margin-left: 30px;
//					background: $resource-info-arrow 0 0/20px auto no-repeat transparent;
//				}
				.resource-type-info-text {
					background-color: $azureish_white2;
					padding: 15px 20px;
				}
			}
			
				.resource-type-info-arrow {
					display: none;
					width: 20px;
					height: 14px;
   				//margin-left: 30px;
					background: $resource-info-arrow 0 0/20px auto no-repeat transparent;
					
					position: absolute;
					bottom: -15px;
					left: calc(100% - 23px);
				}
		}
	}
	
//	#sidebar-second {
//		.block {
//			&.block-webform {
//				display:none;
//			}
//		}
//	}
	section.postscript-first {
		.postscript-section-row-block {
			padding-top: 0;
			
//			.view-display-id-related.section-row,
//			.view-display-id-block.section-row {
//				padding-top: 0;
//				padding-bottom: 0;
//			}
		}
		.section-row {
			padding-top: 0;
		}
	}
}

//.view-resource-series:not(.view-attachment) {
//	.views-row {
//		margin-right: 0;
//		width: 100%;
//
//		float: none;
//		display: inline-block;
//		
//		@media (min-width:540px) and (max-width:939px) {
//			@include span-columns(6);			
//
//			width: calc(50% - #{$cards-separation});
//			margin-right: 1px;
//
//			&:nth-child(2n) { 
//				margin-right:0; 
//			}
//			&:nth-child(2n+1) { 
//				clear:left; 
//			}
//
//			float: none;
//			display: inline-block;
//		}
//		 
//		@media (min-width: 940px) {
//			
//			@include span-columns(3);
//			
//			width: calc(25% - #{$cards-separation});
//			margin-right: 1px;
//		
//			&:nth-child(4n) { 
//				margin-right:0; 
//			}
//			&:nth-child(4n+1) { 
//				clear:left; 
//			}
//
//			float: none;
//			display: inline-block;
//		}
//	}
//	
////	&.view-display-id-node .views-row {
////		@media (min-width: 940px) {
////			@include span-columns(4);			
////
////			width: calc(33% - 4px);
////			margin-right: $cards-separation;
////
////			&:nth-child(3n) { 
////				margin-right:0; 
////			}
////			&:nth-child(3n+1) { 
////				clear:left; 
////			}
////		}
////	}
//}
	

/*  FEEBACK */ //
.node-type-resource {
	main section#content .content-additional {
		padding: 0;
	}
}
.block-webform {
	h2.resource-feedback-toggle {
		display: block;
		padding: 20px 30px;
		width: 100%;
		font: 300 1.556em/1 $title-font-family;
		color: $green_blue;
		background: $ico-expand-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
		border: 1px solid $azureish_white;
		margin-bottom: 0;
		cursor:pointer;

		& + .content {
			form {
				&.webform-client-form::before {
					content: "Your feedback will not be displayed on the site, it’s just for internal use";
					display: block;
				}

				@media(min-width:$narrow) {
					label {
						width: 100px;
						display: inline-block;
						vertical-align: top;
						margin-top: 5px;
					}
				}

				.webform-email {
					input {
						display: inline-block;
						vertical-align: top;
						width: 100%;
						
						@media(min-width:$narrow) {
							width: calc(100% - 125px);
						}
						
						@media(min-width:$wide) {
							max-width: 350px;
						}
					}
					.description {
						width: 100%;
						
						@media(min-width:$narrow) {
							margin-left: 130px;
						}
					
						@media(min-width:$wide) {
							display: inline-block;
							margin-left: 10px;
							width: calc(100% - 490px);
							max-width: 220px;
   						line-height: 1.3;
						}						
					}
				}

				.webform-feedback {
					.form-textarea-wrapper {
						width: 100%;

						@media(min-width:$narrow) {
							width: calc(100% - 125px);
						}
					}
				}		
			}
		}
	}

	.content {
		padding: 0 30px;
		background-color: white;
		
		visibility: hidden;
    height: 0px;
		transition: all 300ms linear 0s;
		overflow: hidden;
		
		form {
			display: none;
		}

		.form-actions {
			margin-top: 1em;
			margin-bottom: 1em;
			text-align: right;
		}
	}

	&.visible {	
		h2.resource-feedback-toggle {
			background: $ico-collapse-blue calc(100% - 30px) 50% / auto 30px no-repeat $glitter;
		}
		
		.content {
			padding: 30px 30px 20px;
			visibility: visible;
			height: auto;
			
			form {
				display: block;
			}
		}
	}			
}

// Help us improve our resources
.node-type-resource .mfp-inline-holder {
	.mfp-content {
		width: 100%;
		background: $white;
		padding: 30px;
		position: relative;
		@include media($narrow) {
			width: 75%;
		}
		@include media(1200px) {
			width: 50%;
		}
		.form-actions {
			float: left;
			margin-right: 1em;
		}
		&:not(.submitted) {
			button.mfp-close {
				position: relative;
				display: inline-block;
				margin: 1rem 0;
				background-color: $action-default; 
				opacity: 1;
				color: $white;
				font: 300 1.111rem/1 $title-font-family;
				width: auto;
				height: auto;
				padding: $button-padding;
				text-indent: -9999px;
				overflow: hidden;
				&:after {
					content: "I'd rather not";
					display: block;
					width:auto;
					height: auto;
					text-indent: 0;
					margin-top: -19px;
				}
				&:hover {
					background-color: $action-hover;
				}
			}
		}
		&.submitted {
			button.mfp-close {
				position: relative;
				display: inline-block;
				margin: 1rem 0;
				background-color: $action-default; 
				opacity: 1;
				color: $white;
				font: 300 1.111rem/1 $title-font-family;
				width: auto;
				height: auto;
				padding: $button-padding;
				text-indent: -9999px;
				overflow: hidden;
				&:after {
					content: "Close";
					display: block;
					width:auto;
					height: auto;
					text-indent: 0;
					margin-top: -19px;
				}
				&:hover {
					background-color: $action-hover;
				}
			}
			.form-help {
				display: none;
			}
			button.mfp-close:hover {
				color: $white;
			}
			.links a.ajax-processed {
				display: none;
			}
		}
	}
}
.member-only-link {
	a {
		color: $white;
		&:hover {
			color: $white;
		}
	}
}
