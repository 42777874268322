.site-title a {
	background:$logo-mobile no-repeat scroll 0 0 transparent;
	
	@include media($narrow) {
		background:$logo-en no-repeat scroll 0 0 transparent;
	}
}
//@include media($narrow) {
//	.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }
//	.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
//	.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }
//}

#site-header {
	background-color:$header-bg-color;
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-outer-wrapper {
		position:relative;
		
		.header-inner-wrapper,
		#main-navigation {
			@include outer-container($site-max-width);
			position: relative;
		}
	}
	.site-branding {
		margin-top:0;
		margin-left:1.25rem;
		position: relative;
		
		@include media(880px) {
			top:0;
		}
		.site-title {
			//position:absolute;
			left:0;
			//top:1.250rem;
			
			float: left;
			margin-top: 1.6250rem;
			margin-bottom: 1.6250rem;
			
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				height:129px;
				width: 57px;

				
				@include media($narrow) {
					width:284px;
				}
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	 
	
	.search-toggle-wrapper {
    position: absolute;
    top: -70px;
		right: 70px;
		//display:none;
		
		@include media($narrow) {
			top: -70px;
    	right: 70px;
		}

		@include media(880px) {
			top: 0;
    	right: 0;
		}

		//@include media(880px) {
			display: block;
			
			.search-toggle {
				height: 70px;
				width: 70px;
				text-indent: -9999px;
				background: transparent $search-icon-white no-repeat scroll 50% 50%;
				background-size: 35px !important;

				//@media (min-width:$narrow) and (max-width:$normal) {
				//	height: 85px;
				//	top: -39px; 
				//}
				
				@include media($narrow) {
					background-size: 35px !important;
					height: 70px;
    			width: 70px;
				}
				
				@include media(880px) {
					background-size: 30% !important;
					height: 50px;
					width: 60px;
				}

				&:hover {
					background: $nav-hover-bg-color $search-icon-white no-repeat scroll 50% 50%;
					cursor: pointer;
				}

				&.active {
					background: $nav-highlight-bg-color $close-icon no-repeat scroll 50% 50%; 

					&:hover {
						background: $nav-hover-bg-color $close-icon-hover no-repeat scroll 50% 50%;
					}
				}
			}
		//}
	}
	
	.block-search {
		display:none;
		
		
		//@include media(880px) {
			display: block;
			right: 0;
		//}
		
		@include media(880px) {
			position: absolute;
		}
		
		#search-block-form {
			display:none;
			
			//@include media(880px) {
				position: absolute;
				right: 0;
				top: 0;
				background: $header-search-bg-color;
				z-index: 1;
				padding: 10px;
			  max-width: 100%;
				
			@include media(880px) {
				top: 51px;				
		    max-width: none;
			}
			//}

			h2 {
				display:none;
			}
			.form-item-search-block-form {
				margin: 0;
				
				@include media(880px) {
					float:left;
				}
				
				input.form-text {
					width:26rem;
					height:2.5rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font; 
					padding:0.375rem 0.75rem;
					margin: 0;
					font-size: 1rem;
										
   				max-width: 100%;
				}
			}
			.form-actions {
				float:left;
				margin:0;
				input.form-submit {
					background: transparent $search-icon no-repeat scroll 0 0;
					background-size:18px;
					width:18px;
					height:18px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.25rem;
					
					&:hover {
						background: transparent $search-icon-hover no-repeat scroll 0 0;
						cursor: pointer;
						background-size:18px;
					width:18px;
					height:18px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.25rem;
					}
				}
			}
		}
	}
	
	.block-menu {
		float:right;
		margin-top:1rem;
		margin-right:0.389rem;
		
		@include media(880px) {
			//margin-right:1.5rem;
			margin-top: 3.35rem;
		}
		.menu {
			li {
				display:inline-block;
				margin:0 0.75rem 0 0;
				
				&.first {
					display: none;

					@include media(880px) {
						display: inline-block;
					}
				}
			}
			

			a {
				font:$header-menu-font;
				color:$header-menu-link-color;

				border: 2px solid $header-menu-link-color;
				border-radius: 6px;
				padding: 6px 15px;
				
				@include media($narrow) {
					padding: 10px 20px;
				}

				&:hover {
					//color:$header-menu-link-hover;
					//background-color: $header-menu-link-color;
					
					color:white;
					background-color: $dark_purple;
					border: 2px solid $dark_purple;
				}
				
				&[href^="/user/register"] {
					color:$header-menu-link-hover;
					background-color: $header-menu-link-color;
					&:hover {
						color:white;
						background-color: $dark_purple;
						border-color: $dark_purple;
					}
					
					display: none;
				}
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
	.view-header-sections {
		display: none;
		
		@include media(880px) {
			display: block;
		}
		
		.views-row {
			padding-top: 3.95rem;
			float: right;

			.card-follow-us-on { 
				position: relative;
				
				li {
					display: inline-block;

					.card-links {
						//display: inline-block;
						margin-right: 10px;
						text-indent: -9999px;

						a {
							border-radius: $card-links-border-radius;
							width: 40px;
							height: 40px;
							display:block;
							margin:0; 
							border: $card-links-border;
							border-color: $razzmic_berry;
							background-size: 20px 20px !important;

							&:hover {
								opacity: $card-links-hover-opacity;
								border-radius: $card-links-hover-border-radius;
								border-color: $dark_purple;

								background-color: $dark_purple !important;
							}
						}

						/* newsletter */
						.newsletter a,
						a[href^="http://eepurl.com"] {
							background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px transparent;
							&:hover {
								background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $action-default;
							}
						}

						/* linkedin */
						.linkedin a,
						a[href^="https://www.linkedin.com"],
						a[href^="http://www.linkedin.com"],
						a[href^="https://linkedin.com"],
						a[href^="http://linkedin.com"] {
							background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px transparent;
							&:hover {
								background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $action-default;
							}
						}

						/* twitter */
						.twitter a,
						a[href^="https://www.twitter.com"],
						a[href^="http://www.twitter.com"],
						a[href^="https://twitter.com"], 
						a[href^="http://twitter.com"] {
							background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px transparent;
							&:hover {
								background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $action-default;
							}
						}

						/* facebook */
						.facebook a,
						a[href^="https://www.facebook.com"],
						a[href^="http://www.facebook.com"],
						a[href^="https://facebook.com"],
						a[href^="http://facebook.com"] {
							background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px transparent;
							&:hover {
								background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $action-default;
							}
						}

						/* google+ */
						.g-plus a,
						a[href^="https://www.plus.google.com"],
						a[href^="http://www.plus.google.com"],
						a[href^="https://plus.google.com"],
						a[href^="http://plus.google.com"] {
							background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px transparent;
							&:hover {
								background: $ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $action-default;
							}
						}
					}
					.blog-link {
						// margin-right: 10px;
						text-indent: initial;
						width: 62px;

						a {
							// border-radius: $card-links-border-radius;
							width: auto;
							//padding: 6px 10px;
							font: $header-menu-font;
							color: $white;
							// height: 40px;
							// display:block;
							// margin:0; 
							// border: $card-links-border;
							// border-color: white;
							// background-size: initial !important;

							// &:hover {
								// opacity: $card-links-hover-opacity;
								// border-radius: $card-links-hover-border-radius;
								// border-color: $dark_purple;

								// background-color: $dark_purple !important;
							// }
							padding: 8px 10px 4px;
							position: absolute;
							top: 0;
							
							&.active {
								background-color: $razzmic_berry;
							}
						}
					}
				}
			}
		}
	}
}

.front .blog-link a.active {
	background-color: transparent !important;
}

.not-logged-in #site-header {
	.block-menu {
		.menu {
			li.first {
				display: inline-block;
			}
		}
	}
}

@media (max-width:310px) {
	.not-logged-in #site-header {
		.wrapper-site-branding .site-title {
			margin-top: 5rem;
		}
		.wrapper-region-header .block-menu {
			position: absolute;
	    right: 0;
		}
	}
}

@media (max-width:400px) {
	.logged-in #site-header {
		.wrapper-site-branding .site-title {
			margin-top: 5rem;
		}
		.wrapper-region-header .block-menu {
			position: absolute;
	    right: 0;
		}
	}
}
