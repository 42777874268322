
a.side-button,
a.use-ajax-comments,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.form-actions a.edit-cancel { 
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  //padding: 0.556rem 1em;
	padding: $button-padding;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.view-blog > .view-footer > p > a.spamspan,
a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  //padding: 0.75em 1em;
	padding: $button-padding;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
	
//	background:$button-link-default-bg;
//	border-color: $button-link-default-border-color;
//	color: $button-link-default-color;
//
//	&:hover {
//		background:$button-link-default-hover-bg;
//		border-color: $button-link-default-hover-border-color;
//		color: $button-link-default-hover-color;
//	}
}

a.side-button {
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
	white-space: pre-line;
	line-height: 1.3;
}