table {
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 $vert-space;
	width: auto;    /* Why not "100%" ? */
	display: block; /* Why not "table"? */

	caption {
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color::$table-caption-text-color;
		font: $table-caption-font;
		padding:$vert-space/3 $vert-space/2;
		text-align: left;
	}

	th {
		color: $dark_purple;
		background:$table-header-bg-color;
		border-bottom: $table-header-border;
		font-family: $title-font-family;
		font-weight: normal;
		padding: $vert-space/2 0;
		text-align: left;
		word-wrap: break-word;
	}

	td {
		border-bottom: $table-border-color;
		line-height: $base-line-height;
		padding: $table-padding;
		// border-left:$table-cell-border;
		border-right:$table-cell-border;
		word-wrap: break-word;
	}

	thead {
		th {
			background-color: $table-header-bg-color;
			border-top: $table-header-border;
			border-right: $table-header-border;
			border-bottom: 0px;
			padding: $table-padding;
		}
		tr:first-child {
			th {
				&:first-child {
					border-radius: 6px 0 0 0;
					border-left: $table-header-border;
				}
				&:last-child {
					border-radius: 0 6px 0 0;
				}
			}		
		}
	}
	
	tr,
	td,
	th {
		vertical-align: middle;
	}

	tbody {
		background-color: $table-bg-color;
		
		th {
			border-left: $table-border;
			border-right: $table-border;
			border-top: $table-border;
			background-color: $isabelline !important;
			padding: $table-padding;
			border-bottom: none;
		}

		tr:hover > td, tr:hover > th {
			background-color: $table-hover-bg-color;
		}
//		tr:first-child th {
//			border-top: $table-border;
//		}		
		tr:first-child td,
		tr:first-child th {
			border-top: $table-header-border;
		}
		tr:last-child td {
			border-bottom: $table-border;
		}
		tr:nth-child(2n+0) {
			background-color: $table-stripe-bg;
			&:hover > td {
				// background-color: $table-stripe-bg-hover;
			}
		}
		tr:last-child {
			th,
			td:first-child {
				border-radius: 0 0 0 6px;
				border-bottom: $table-border;
			}
			td:last-child {
				border-radius: 0 0 6px 0;
			}
		}
		tr td:first-child {
			border-left: $table-border;
		}
//		tr td:first-child {
//			border-left: $table-cell-border;
//		}
		td {
			border-bottom: 0px;
			border-top: $table-border;
			padding: $table-padding;

			button {
				display: inline-block;
				font-size: .7em;
				line-height: $base-line-height;
				margin-bottom: .3em;
				margin-right: 0.5em;
				outline: none;
				padding: .3em 1em;
				width: 100%;

				@include media($narrow) {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}
.default-table {
	table {
		thead {
			th {
				background-color: white;
				&:first-child {
					border-radius: 0;
				}
				&:last-child {
					border-radius: 0;
				}
			}
		}
		tbody {
			background-color: white;
			tr {
				background-color: white;
				&:last-child {
					td {
						&:first-child {
							border-radius: 0;
						}
						&:last-child {
							border-radius: 0;
						}
					}
				}
			}
		}
	}
}
