.section-with-curated-links {
	.section-inner {
		overflow: visible;
		
		.view-curated-links.view-display-id-featured_first {

			@media (max-width: $normal) {
				& > .view-content-main {
					margin-bottom: 20px;
				}
			}
			
			@media (min-width: $normal) {
				& > .view-content-main {
					float: left;
					display: block; 
					margin-right: 20px;
					width: calc((100%/12)*7 - 10px);
					
					.views-row,
					.views-row a.card {
						height: 100%;
					}
				}

				& > .attachment {
					float: left;
					display: block;
					margin: 0;
					width: calc((100%/12)*5 - 10px);

					.view-display-id-featured_next {
						//min-height: calc(100%/2 + 40px);

						.view-content {
							height: 100%;
							position: relative;

							.views-row {
								//height: calc(50% - 40px/3);
								//min-height: calc(50%);
							}
						}
					}

					.view-display-id-promo {
						//height: calc(100%/2 - 40px/3);
						//height: calc(100%/2 - 40px);
						height: 50%;
						
						.promo .promo-inner {
							background-attachment: initial !important;
						}
					}
				}
			}

			& > .attachment {
				.card-promo-text {
					@media (min-width: $normal) {
						padding: 70px 20px 20px;
						max-width: 70%;
					}					
					
					@media (min-width: $wide) {
						padding: 120px 20px 20px;
						max-width: 70%;
					}

					.promo-title {
						font: 300 2rem/1.33 $title-font-family ;
						color: white;
						margin-bottom: 1rem;
					}
//					.promo-link a {
//						font: 1.111rem/1 $title-font-family ;
//						background: $arrow-long-rose calc(100% - 20px) 50% / auto 14px no-repeat white;
//						border-radius: 6px;
//						padding: $button-padding;
//						padding-right: 60px;
//						display: inline-block;
//					}
				}
			}
		}
	}
}