.page-bgs-groups,
.page-about-national-regional-groups {
	
	h1 {
		padding: 30px 30px 0;
		background-color: white;
		margin: 0;
		text-align: left;
	}
	
	.view-display-id-bgs_groups,
	.view-display-id-national_regional {	
		& > .view-header {
			padding: 20px 30px 30px;
			background-color: white;
		}
	}
}
// Group page hashtag view
.group-hashtag {
	.view-twitter-hashtag {
		padding: 40px 20px;
	}
}

// Group node Officers and Contacts
 // .view:not(.view-display-id-connect) .views-row:not(.views-row-last)
main .side-row .view-id-group_node.view-display-id-officers .view-id-officers_contacts.view-display-id-block {
	.views-row:not(.views-row-last) {
		padding-bottom: 30px;
		margin-bottom: 30px;
		.views-row-last {
			margin-bottom: 20px;
		}
	}
	.card-relationship-type {
		font-family: $title-font-family;
		font-size: 0.889rem;
		font-weight: 600;
		line-height: 1.125;
		margin-top: 10px;
		margin-bottom: 8px;
		text-transform: uppercase;
	}
	.card-email a {
		color: $green_blue;
		font-weight: 300;
	}
}