.view-attachment,
.view-resource-download,
.view-event-download,
.view-event-download,
.view-display-id-job_attachment {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}

	.views-row:not(.views-row-last) {
		padding-bottom: $vert-space;
		border-bottom: $tertiary-border;
		margin-bottom: $vert-space*1.5;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

  .attachment-link {
    display:block;
    overflow: hidden;
    margin-bottom:$vert-space; 
    //color:$action-alt-default;
		
    .file-cover {
      float:left;
      margin:0 $horz-space 0 0;
    }
		
		.resource-cover {
			width: 100%;
			max-width: 300px;
			display: block;
			margin: 0 auto 20px;
			
			@media (min-width:$narrow){
				float: right;
				margin: 0 0 20px 20px;
			}

			@media (min-width:$normal) {
				float: none;
				margin: 0 auto 10px;
				text-align: center;
				
				img {
    			display: inline;
				}
			}
		}
    
		.file-icon {
      .file-name {
        overflow:hidden;				
				font: normal 1.333em/1.3 $title-font-family;
        margin:0 0 $vert-space/2;
        word-break: break-word;
      }
      //float:left;
			overflow: hidden;
      min-width:4rem;
      .file-details {
        background:$ico-download-hover no-repeat scroll 0 4px/30px 35px;
        display:inline-block;
        .file-extension {
          padding:0 0 4px $horz-space*2;
          text-transform:uppercase;
          font-size:1rem;
          line-height:1;
          //text-align:center;
          font-weight:600;
          color:$davys_grey;
        }
        .file-size {
          padding:0 0 0 $horz-space*2;
          font-size:0.889rem;			
          //text-align:center;
          color:$davys_grey;
          border:$secondary-border;
          border-top:none;
        }
      }
    }
    &:hover {
      //color:$action-alt-hover;
      .file-details {
        background:$ico-download no-repeat scroll 0 4px/30px 35px;
      }
    }
  }

// /* FILE TYPE COLOURS */

//  .file-type-docx, .file-type-doc {
//    .file-extension {
//      background:$docx_color;
//      color:white;
//    }
//  }
//  .file-type-xlsx, .file-type-xls {
//    .file-extension {
//      background:$xlsx_color;
//      color:white;
//    }
//  }
//  .file-type-pptx, .file-type-ppt {
//    .file-extension {
//      background:$pptx_color;
//      color:white;
//    }
//  }
//  .file-type-pdf {
//    .file-extension {
//      background:$pdf_color;
//      color:white;
//    }
//  }
//  .file-type-rtf, .file-type-txt {
//    .file-extension {
//      background:$txt_color;
//      color:white;
//    }
//  }
//  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
//    .file-extension {
//      background:$jpg_color;
//      color:white;
//    }
//  }
//  .file-type-zip, .file-type-rar, .file-type-7z {
//    .file-extension { 
//      background:$zip_color;
//      color:white;
//    }
//  }

} 



.view-resource-download {
	.views-field-view + .views-field-view-1 {
		width: calc(100% + 40px);
		margin-left: -20px;
		padding: 20px 20px 0;
		border-top: 2px solid $side-row-title-bg;
	}
}

.one-sidebar main #content,
section.postscript-first {
	.view-attachment {	
		@media (min-width:$narrow){
			.view-content {
				.views-row {
					width: calc(50% - 30px);
					border-bottom: none;
					display: inline-flex;
					padding-bottom: $horz-space;
          padding-top: $horz-space;
          margin-bottom: 0;
          border-bottom: $tertiary-border;

					&.views-row-even {
            padding-left: $vert-space;
          }

          &.views-row-odd {
						border-right: $tertiary-border;
						padding-right: $vert-space;
            margin-right: -4px;

						a {
							padding-right: $vert-space;
							// margin-bottom: 0;
						}
					}
          &.views-row-1,
          &.views-row-2 {
            padding-top: 0;
          }
          &.views-row-last {
            padding-bottom: 0;
            border-bottom: none;
          }
          &.views-row-first.views-row-last {
            border: none;
          }
          &:nth-last-child(2):not(.views-row-even) {
            border-bottom: none;
          }
				}
			}
		}
	}
}
.two-sidebars main #content {
	.view-attachment {	
		@media (min-width:1100px) {
			.view-content {
				.views-row {
					width: calc(50% - 30px);
					border-bottom: none;
					display: inline-flex;
					padding-bottom: $horz-space;
          padding-top: $horz-space;
          margin-bottom: 0;
          border-bottom: $tertiary-border;

					&.views-row-even {
            padding-left: $vert-space;
          }

          &.views-row-odd {
						border-right: $tertiary-border;
						padding-right: $vert-space;
            margin-right: -4px;

						a {
							padding-right: $vert-space;
							// margin-bottom: 0;
						}
					}
          &.views-row-1,
          &.views-row-2 {
            padding-top: 0;
          }
          &.views-row-last {
            padding-bottom: 0;
            border-bottom: none;
          }
          &.views-row-first.views-row-last {
            border: none;
          }
          &:nth-last-child(2):not(.views-row-even) {
            border-bottom: none;
          }
				}
			}
		}
	}
}
