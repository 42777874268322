.view-promo,
.view-curated-links.view-display-id-promo {
	.promo {
		.card-promo-text {
			position: relative;
			padding: 60px 30px;
			text-align: left;

			@media (min-width: $narrow) {
				padding: 60px 0 60px 100px;
				max-width: 70%;
			}

			@media (min-width: $normal) {
				padding: 60px 0 60px 140px;
				max-width: 60%;
			}

			@media (min-width: $wide) {
				padding: 60px 0 60px 140px;
				max-width: 50%;
			}

			.promo-title {
				font: 300 2rem/1.33 $title-font-family ;
				color: white;
				margin-bottom: 1rem;
			}

			.promo-description p {
				font: 1.111rem/1.3 $base-font-family ;
				color: white;	
				margin-bottom: 20px;
			}

			.promo-link a {
				font: 1.111rem/1 $title-font-family ;
				background: $arrow-long-rose calc(100% - 20px) 50% / auto 14px no-repeat white;
				border-radius: 6px;
				padding: $button-padding;
				padding-right: 60px;
				display: inline-block;
				
				&:hover {
					background: $arrow-long-white calc(100% - 20px) 50% / auto 14px no-repeat $action-hover;
					color: white; 
				}
			}
		}

		@media (min-width: $normal) {
			.promo-inner {
				background-attachment: fixed !important; 
			}
		}
	}
}

// @media (min-width: $normal) {
// 	.page-node-275 .palette-highlight.section-with-promo .promo-inner,
// 	.page-node-253 .section-with-promo .promo-inner {
// 			background-attachment: fixed !important; 
// 	}
// }

.age-ageing-rhs-promo {
	.view-promo .promo {
		.card-image {
			text-align: center;
			
			.card-image-wrapper {
				max-width: 480px;
				display: inline-block;
				border: 2px solid white;
			}
		}
		.card-promo-text {
			padding: 20px 0 0;
			max-width: 100%;

			.promo-link a {
				background: $action-default;
				color: white; 
				padding: $button-padding;

				&:hover {
					background: $action-hover;
					color: white; 
				}
			}
		}
	}
}