/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs,
.view-id-event_fee_and_sessions.view-display-id-page {
	margin-bottom: 20px;
	.views-row.active,
	h3.active {
		position: relative;
		&:after {
			display: block;
			width: calc(100% + 60px);
			content: "";
			height: 100%;
			background: $faq-bg-color;
			position: absolute;
			top: 0;
			left: -30px;
			z-index:0;
		}
	}

	.faq-question{
		
		position: relative;
		z-index: 1;
		
		&:before {
			display: block;
			width: calc(100% + 60px);
			content: "";
			height: 2px;
			background-color: $isabelline;
			margin-left: -30px;
			z-index: 0;
		}
		
		.card-qa-answer {
			display: none;
			padding: 0 0 15px 0;	
			box-sizing: border-box;

			:last-child {
				margin-bottom: 0;
			}
		}

		.card-qa-question,
		h3 {
			text-decoration: none;
			color: $primary;
			padding: 15px 30px 15px 0;
			line-height: 1.333rem;
			display: block; 
			margin-bottom: -1px;
			cursor: pointer;
			box-sizing: border-box;
			font-size: 1.333rem;
			background: $ico-expand no-repeat scroll 100% 50% / 20px 20px transparent;
			color: $action-hover; 

			&.active {
				color: $action-default;
				background: $ico-collapse no-repeat scroll 100% 50% / 20px 20px transparent;
			}
			
			&:hover{
				color: $green_blue;
				background: $ico-expand-blue no-repeat scroll 100% 50% / 20px 20px transparent;
 
				&.active {
					color: $green_blue;
					background: $ico-collapse-blue no-repeat scroll 100% 50% / 20px 20px transparent;
	
					// &:before {
					// 	display: block;
					// 	width: calc(100% + 60px);
					// 	content: "";
					// 	height: 100%;
					// 	background: $faq-bg-color;
					// 	position: absolute;
					// 	top: 0;
					// 	left: -30px;
					// 	z-index:-1;
					// }
				}			
			}
		} 
	}
	
	/*.ico-collapse, .ico-expand {
		width: 20px;
		height: 20px;
		float:left;
		margin-right: 20px;
	}

	.ico-collapse {
		
	}
	.ico-expand {
		
	}*/

}