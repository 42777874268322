// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	
	transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	} 
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}
  
	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}
	.card-text {
		border-top:$card-text-border;
		padding: $card-text-padding;
		position: relative;
		word-wrap: break-word;
		
		a { word-wrap: break-word; }
	}
	
	.card-image-wrapper {
    margin-bottom: -2px;
    position: relative;
	}
	.card-group-type {
		position: absolute;
		bottom: 10px;
		border-radius: 0 2px 2px 0;
		background: $imperial;
		text-transform: uppercase;
		padding: 7px 10px;
		font-size: 0.778rem;
		font-weight: 600;
		line-height: 1rem;
	}
	.has-coverage {
		font: 600 0.778rem/1 $base-font-family;
		padding: 5px 10px;
		color: $dingy_dungeon;
		background-color: #FBEFF3;
		text-transform: uppercase;
		display:inline-block;
		margin: 10px 5px 0 0;
	}
	&:hover {
		.has-coverage {
			color: $dingy_dungeon;
		}
	}
}

// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left: $cards-separation; //2.35765%;
		text-align:center; 
		
		//padding-top: 10px;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	//float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	
	text-align: right;
	float: none;
	padding-top: 10px;
	
	a {
		border-radius: $button-border-radius;
		border: none;
		cursor: pointer;
		display: inline-block;
		//padding: 0.556rem 1em;
		padding: $button-padding;
		text-decoration: none;
		user-select: none;
		vertical-align: middle;
		white-space: nowrap;
//		&:after {
//			content: "";
//			display: inline-block;
//			width: 18px;
//			height: 18px;
//			//border-radius: 50%;
//			margin-left: 10px;
//			vertical-align: sub;
//		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}


.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.889rem;
		line-height:1.111;
		text-transform:uppercase;
	}
}
.card-citation {
	font-style:italic;
}
.card-date,
.card-location,
.card-organiser,
.card-date-display-override {
	//font-size:0.889rem;
	color:$card-date-text-color;
	font:$card-date-font;
	line-height:1.111;
	text-transform: uppercase;
	margin: 6px 0 10px;
}
.card-organiser{
	text-transform: none;
	font-weight: 400;
}
.card-location {
	background: $ico-location no-repeat 0 1px / auto 14px transparent;
	padding-left: 15px;
	text-transform: none;
}
.card-location-link {
	a {
		background: $ico-location-pink no-repeat 0 5px / auto 14px transparent;
		padding-left: 15px;
		text-transform: none;
	}
}
.event-date-wrapper {
	background: $dark_purple;
	padding: 10px 23px;
	&:after {
	  content: "";
	  background: $bgs-shadow-icon no-repeat bottom 18px left 18px / 56px;
	  opacity: 0.06;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  position: absolute;
	  @media(max-width: 540px) {
	  	background: none;
	  }
	}
	.event-date {
		color: $white;
		// float:right;
		text-align: right;
		font-size: 2.333rem;
		font-weight: 400;
		.event-year {
			font-weight: 300;
		}
	}
}
//.card-date-display-override {
//	font-size: 0.875em;
//}
.card-content + .card-footer{
	padding-top: 5px;
}
.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
//	padding:12px 20px 10px;
	margin: 6px 0 5px;
	text-transform: uppercase;
	padding: 0;
	.date-day,
	.date-month {
		font-weight: 300;
		font-family: $title-font-family;
	}
	.date-day {
		font-size: 3.333rem;
	}
	.date-month {
		font-size: 1.667rem;

	}
}
.view-display-id-homepage {
	.card-start-date {
		text-align: left;
		padding: 0;
	}
}

.card-type {
	font-size:0.889rem;
	line-height:1.111;
	text-transform: uppercase;
}
.views-row {
	.card-date,
	.card-location,
	.card-display-date {
		//float: right;
		
		@media (min-width:$narrow) and (max-width:$normal) {
			float: left;
			clear: both;
		}
	}
	.card-type {
		float: left;
	}
	
	a.card {
		.card-date,
		.card-location,
		.card-display-date {
			//float: right;

			@media (min-width:$narrow) and (max-width:$normal) {
				float: none;
				clear: both;
			}
		}
		.card-type {
			float: none;
		}
	}
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
.card-journal {
	display: inline-block;
	font-weight: 400;
	font-size: 0.875em;
	&:after {
		content:"|";
		margin: 0 0px 0 2px;	
	}
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
	
	// BEFORE REMOVE THIS LINES: this is based on the "members only" tag and toppics on the card-footer, we need to find an alternative if this affect other views
	display: table-cell;
	vertical-align: bottom;

}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
@include card-hidden-summary;



// /* EMPTY IMAGES */
.card-image-empty {
	background-color: $pastel_gray;
}
.view-team {
	.card-image-empty {
		min-height: 70px;
	}
}

// Resource cards
.resource-card,
.event-card {
	@include media(540px) {
		height: calc(460px - 11.5vw);
	
		.card-text {
			height: 100%;
			width: 100%;
		}
		.card-footer {
			position: absolute;
			bottom: 20px;
		}
	}
	.card-views-conditional {
		display: inline-block;
	}
}
.event-card {
	@include media(540px) {
		height: calc(460px - 10vw);
	}
}
.content-additional .resource-card,
.content-additional .event-card {
	@include media(540px) {
		height: calc(490px - 11.5vw) !important;
	}
}

.card-summary {
	overflow:hidden;
	display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
	
	.card-summary-tags {
		font-size: 0.889rem;

		h6 {
			margin-top: 10px;
			font-size: 1rem;
		}
	}
}
.list {
	.resource-card {
		@include media (540px) {
			height: calc(210px - 4vw);
		}
		.card-views-conditional {
			float: left;
		}
	}
}

// member cards
.card.member-card {
	.card-prefix-id,
	.card-first-name,
	.card-last-name,
	.card-suffix-id {
		display: inline-block;
	}
	.card-last-name {
		margin-bottom: 0.5em;
	}
	.card-job-title {
		font-weight: 600;
		line-height: 1.333em;
		margin-bottom: 0.250em;
	}
	.card-place-of-work-213 {
		font-size: 0.889rem;
		line-height: 1.111em;
	}
}

	
.card-edit-link {
	margin-top: 10px;
	
	a {
		@include button-states($button-bg, $button-text, $button-border-color);
		font-size: 0.875rem;
		font-weight: bold;
		padding: 5px 15px;
		text-transform: uppercase;
		
		&:hover,
		&:focus {
			background-color: $button-hover-bg;
			color:  $button-hover-text;
		}
	}
}
