.section-row {
	
	&.img-left {
		.card-body,
		.card-description {
			ul {
				overflow: hidden;
			}
		}
	}
	
	&.img-centre,
	&.vid-centre {
		.section-inner {
			.card-original,
			.card-video {
				width:100%;
				margin-bottom:1rem;
				text-align: center;
				
				
				picture {
					display: inline-block;
					border-radius: 6px;
					overflow: hidden;
					
					.caption {
						display: inline-block;

						img {
							max-width:100%;
							height:auto !important;
							margin:0 auto;
						}
					}

					& > img {
						display: inline-block;
					}
				}
				
				iframe {
					width:100%;
					margin:0 auto;
				}
			}
			.card-video {
				margin: 0 auto 2rem;
				
				@media(min-width:$normal) {
					width:70%;					
				}
			}
		}
	}

	&.img-left,
	&.vid-left,
	&.img-right,
	&.vid-right {
		.section-inner {
			.card-original, 
			.card-video {
				text-align: center;
			}
		}
	}
	
	&:not(.img-left):not(.vid-left):not(.img-right):not(.vid-right) {
		.section-inner {
			.card-original,
			.card-video {
				
				picture,
				.photoswipe-gallery {
					display: inline-block;
					border-radius: 6px;
					overflow: hidden;
				}
			}
		}
	}
	
	
	@media(min-width:$narrow) {
		&.img-left,
		&.img-right {
			.section-inner {
				.card-original{
					max-width:calc(40% - 0.625rem);
					margin-bottom:1rem;				
				}
			}
		}

		&.vid-left,
		&.vid-right {
			.section-inner {
				.card-video {
					width:calc(40% - 0.625rem);
					margin-bottom:1rem; 
				}
			}
		}

		&.img-left,
		&.vid-left {
			.section-inner {
				.card-original, 
				.card-video {
					float:left;
					margin-right:1.25rem;
				}
			}
		}
		&.img-right,
		&.vid-right {
			.section-inner {
				.card-original,
				.card-video {
					float:right;
					margin-left:1.25rem;
				}
			}
		} 
	}
	
	
	// /* BODY IMAGES */ //
	.section-inner > .card-body {
		& > p:not(.img-left) {
			& > img,
			& > .caption {
				float: right;
				clear: right;
				margin-left:1.25rem;
			}
		}
		& > p.img-left {
			& > img,
			& > .caption {
				float: left;
				clear: left;
				margin-right:1.25rem;
			}
		}
		& > p {
			& > img,
			& > .caption {
				border-radius: 6px;
				margin-bottom: 1rem;
				overflow: hidden;
			}
		}
	}
}

.sidebar-second {
	main {
		.section-row {
			&.vid-centre {
				.section-inner {
					.card-video {
						@media(min-width:$normal) {
							width:100%;					
						}
					}
				}
			}
		}
	}
}