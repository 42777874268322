.view-id-topic_node.view-display-id-page_1 {
  display: flex;
  flex-direction: column;
  .view-header {
    background-color: transparent;
    padding: 20px 30px 10px;
    h2 {
      text-align: center;
    }
    .topic-intro {
      text-align: center;
      margin-bottom: 2em;
    }
  }
  
  .attachment.attachment-before {order: 1}
  .view-filters {order: 2}
  .view-header {order: 3}
  .view-footer {order: 4}
  .view-content.view-content-main {order: 5}
  .item-list {order: 6}
  .attachment.attachment-after {
    order: 7;
    
    .view-footer {order: 1}
    .view-content {order: 2}
    .item-list {order: 3}
  }
  
  .view-filters {
    position: relative;
    // margin-top: 8px;
    // background-color: transparent;
    .views-exposed-form {
      display: block !important;
    //   .fieldset-wrapper {
    //     display: inline-block;
    //     padding: 20px 30px;
    //     background-color: $white;
    //     box-shadow: $box-shadow;
    //     #edit-type-selective-wrapper {
    //       width: 100%;
    //       padding: 0;
    //       display: flex;
    //       label[for="edit-type-selective"] {
    //         margin-right: 1em;
    //       }
    //       .views-widget {
    //         width: 100%;
    //         max-width: 100%;
    //         .bef-checkboxes {
    //           display: flex;
    //           label {
    //             font-size: 1.125rem;
    //           }
    //         }
    //       }
    //     }
    //   }
      .views-exposed-widgets {
        position: absolute;
        right: 0;
        bottom: -80px;
        .views-widget-filter-secondary {
          .fieldset-wrapper {
            padding: 0;
            label[for="edit-type-selective"] {
              font: 400 1.111rem/1 $title-font-family;
              min-width: auto;
            }
            #edit-type-selective-wrapper {
              width: 277px !important;
              float: right;
              padding-right: 0;
              margin-top: 23px;
            }
            #edit-type-selective {
              width: auto;
              min-width: 200px;
            }
          }
        }
      }
    }
  }
	
	@media (min-width:540px) {
		.attachment.attachment-before .views-row.views-row-first.views-row-last {
			width: 100%;
			
			.card {
				
				.card-image {
					width: calc(50% - 30px/2);
					float: left;

					& + .card-with-summary {
						width: calc(50% + 30px/2) !important;
						float: left;
					}
				}
				
				&:hover {
					.card-with-summary {
						width: 100% !important;
					}
				}
		
				@media (min-width:720px) {					
					&,
					&:hover {
						.card-image {
							width: 40% !important;
						}
						
						.card-image,
						.card-image-wrapper {
							display: block;
						}

						.card-text.card-with-summary {
							width: 60% !important; //calc(50% + 30px/2) !important;

							h3,
							.card-summary-wrapper {
								opacity: 1;
								height: auto;
								display: block;
							}
						}
					}
				}
			}
		}
	}	
}

.page-topics-test {
  h1#page-title {
    text-align: left;
  }
  // .view-id-topic_node.view-display-id-attachment_1 .view-header h2 {
  //   text-align: center;
  // }
}


// SWITCH VIEWS //

.view-topic-node {
  .layout-trigger {
    margin-right: 0!important;
  }
}

.view-topic-node.view-display-id-page_1 {
	.topics-view-switch {
		margin-bottom: 30px;
		width: 100%;
		
		@include media (540px) {
			display: flex;
			border-bottom: 4px solid $pastel_gray;
		}
		
		.view-mode-switch {
			font: 300 1.333rem/1 $title-font-family;
			color: $davys_grey;
			display: block;
			padding: 5px 20px;
			margin-bottom: 10px;
			border-left: 4px solid transparent;
			
			@include media (540px) {
				border: none;
				border-bottom: 4px solid transparent;
				padding: 0 20px 15px;
				margin-bottom: -4px;
				display: inline-block;
			}
			
			&.active,
			&.active:hover {
				color: $maximum_purple;
				border-left: 4px solid $maximum_purple;
				
				@include media (540px) {
					border: none;
					border-bottom: 4px solid $maximum_purple;
				}
			}
			&:hover {
				color: $dingy_dungeon;
				cursor:pointer;
			}
		}
	}
	
	
	& > .view-content-main,
  & > .view-footer,
  & > .item-list,
  
	.attachment .view-display-id-news,
  .attachment .view-display-id-policies,
  .attachment .view-display-id-events,
	.attachment .view-display-id-blogs,
  .attachment .view-display-id-resource_series,
	.attachment .view-display-id-sig {
		display:none;
	}  
  
	&.view-mode-default {
		& > .view-content-main,
    & > .view-footer,
    & > .item-list {
			display:block;
		}
	}
	&.view-mode-news {
		.attachment .view-display-id-news {
			display:flex;
			
			//you are a colour red!
      //shut up pipe!
		}
	}
  &.view-mode-policies {
		.attachment .view-display-id-policies {
			display:flex;
		}
	}
	&.view-mode-blogs {
		.attachment .view-display-id-blogs {
			display:flex;
		}
	}
  &.view-mode-events {
		.attachment .view-display-id-events {
			display:flex;
		}
	}
	&.view-mode-resource-series {
		.attachment .view-display-id-resource_series {
			display:flex;
		}
	}
  &.view-mode-sig {
		.attachment .view-display-id-sig {
			display:flex;
		}
	}
}
