main {
	background-color:$main-page-bg;

	//#content { padding:0 $horz-space/2; }
}
.postscript-first {
	background-color:$main-page-bg;
}

.front main {display: none}

.outer-wrapper {
	@include outer-container($site-max-width);
}
 
hr {
	border:none;
	border-bottom:$primary-border;
	margin: $vert-space*1.5 auto; 
	width: 80%;
}
p + hr {
	margin: $vert-space*1.5 auto;
}
//p + hr,
ul + hr,
ol + hr,
address + hr,
pre + hr,
blockquote + hr {
	margin-top: $vert-space;
}


// /* IMAGES */ //
.caption {
	display: block;
	text-align: left;
	font-size: 1rem;

	p {
		background-color: $caption-bg-color;
		color: $caption-text-color;
		line-height: 1.42857142857143;

		&:last-child {margin-bottom: 0;}
		
		.caption-wrapper {
			margin: 0 0 1em;
			padding: 8px 10px;
			display: block;
		}
	}
}

.section-row:not(.view-type-slider) {
	.section-inner > div:not(.card-view){
		&.card-original,
		&.card-photo {
			@include card-radious-content-image();
		}
	}
}
.section-row.img-centre:not(.view-type-slider) {
	.section-inner > div:not(.card-view){
		&.card-original,
		&.card-photo {
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}
}

.node-type-person .card-photo picture,
.card-body p > img {
	@include card-radious-content-image();
	display: inline-block;
}

.view-footer {
	clear: both;
}