@mixin card-hidden-summary {
	.section-row:not(.row-max-1) {
		@media (min-width:540px) {
			.view {
				.card {
					.card-text.card-with-summary {
						height: 100%;
						width: 100%;

						.card-summary-wrapper {
							//min-height: 100%;
							width: 100%;
							opacity:0;
							height: 0;
							position: relative;
							overflow: hidden;
							transition: none;

							@media (min-width:$narrow) and (max-width:$normal) {
								font-size: 0.875em;
								margin-top: -5px;
							}

						}
					}
					.card-image + .card-with-summary {
						position: relative;
					}

					&:hover {
						.card-text.card-with-summary {
							background: $card-text-with-summary-hover-bg !important;
							color: $card-text-with-summary-text-color !important;
							text-overflow: ellipsis;
							width: 100% !important;

							.card-title,
							.card-title-et,
							.card-title-field {
								color: $card-text-with-summary-title-color !important;

							}

							.card-summary-wrapper {
								opacity:1;
								height: auto;
								transition: all 0.375s ease-in;
							}

							//@media (min-width:$narrow) /*and (max-width:$normal)*/ {
							h3 {
								display: none;
							}
							//}
						}

						.card-image +.card-text.card-with-summary,
						.card-logo +.card-text.card-with-summary,
						.card-image-wrapper +.card-text.card-with-summary  {
							position: absolute;
							bottom: 0;
						}
					}
				}
				
				.views-row-first.views-row-last.match-heights .card,
				.views-row:not(.match-heights) .card {
					&:hover {
						.card-image +.card-text.card-with-summary,
						.card-logo +.card-text.card-with-summary,
						.card-image-wrapper + .card-text.card-with-summary  {
							position: relative;
							
							.card-date-display-override,
							.card-date,
							.card-footer,
							h3 {
								display: block;
							}
							
							.card-summary {
								opacity:0;
								height: 0;
							}
						}
					}
				}
			}
		}
	}
}

@mixin card-hidden-summary-view {
	.views-row {
		.card {
			.card-text.card-with-summary {
				.card-summary-wrapper {
					margin-bottom: 10px;
				}
			}
		}
	}
	
	&:not(.search-page-content-type),
	&:not(.search-page-content-type-tpl) {
		@media (min-width:540px) {
			.views-row {
				.card {
					.card-text.card-with-summary {
						height: 100%;
						width: 100%;

						.card-summary-wrapper {
							min-height: 100%;
							width: 100%;
							opacity:0;
							height: 0;
							position: relative;
							overflow: hidden;
							margin-bottom: 0px;

							@media (min-width:$narrow) and (max-width:$normal) {
								font-size: 0.875em;
							}

						}
					}
					.card-image + .card-with-summary {
						position: relative;
					}

					&:hover {
						.card-text.card-with-summary {
							background: $card-text-with-summary-hover-bg !important;
							color: $card-text-with-summary-text-color !important;
							text-overflow: ellipsis;
							width: 100% !important;

							.card-title,
							.card-title-et,
							.card-title-field {
								color: $card-text-with-summary-title-color !important;

							}

							.card-summary-wrapper {
								opacity:1;
								height: auto;
								transition: all 0.375s ease-in;
							}

							.card-date-display-override,
							.card-date,
							.card-footer {
								display: none;
							}

							h3 {
								display: none;
							}
						}

						.card-image +.card-text.card-with-summary,
						.card-logo +.card-text.card-with-summary,
						.card-image-wrapper +.card-text.card-with-summary  {
							position: absolute;
							bottom: 0;
						}
					}
				}
			}
		}
	}
	
	&.search-page-content-type,
	&.search-page-content-type-tpl {
		@media (min-width:720px) {
			.views-row {
				.card {
					.card-text.card-with-summary {
						height: 100%;
						width: 100%;

						.card-summary-wrapper {
							min-height: 100%;
							width: 100%;
							opacity:0;
							height: 0;
							position: relative;
							overflow: hidden;
							transition: all 0.375s ease-in;
							margin-bottom: 0px;

							@media (min-width:$narrow) and (max-width:$normal) {
								font-size: 0.875em;
								// margin-top: -10px;
							}

						}
					}
					.card-image + .card-with-summary {
						position: relative;
					}

					&:hover {
						.card-text.card-with-summary {
							background: $card-text-with-summary-hover-bg !important;
							color: $card-text-with-summary-text-color !important;
							text-overflow: ellipsis;
							width: 100% !important;

							.card-title,
							.card-title-et,
							.card-title-field {
								color: $card-text-with-summary-title-color !important;

							}

							.card-summary-wrapper {
								opacity:1;
								height: auto;
							}

							.card-date-display-override,
							.card-date,
							.card-footer,
							.card-display-date {
								display: none;
							}

							//@media (min-width:$narrow) /*and (max-width:$normal)*/ {
								h3 {
									display: none;
								}
							//}
						}

						.card-image +.card-text.card-with-summary,
						.card-logo +.card-text.card-with-summary,
						.card-image-wrapper +.card-text.card-with-summary  {
							position: absolute;
							bottom: 0;
						}
					}
				}
				
				&.views-row-first.views-row-last {
					.card {
						&:hover {
							.card-image +.card-text.card-with-summary,
							.card-logo +.card-text.card-with-summary,
							.card-image-wrapper +.card-text.card-with-summary  {
								position: relative;
								bottom: 0;
							}
							
							.card-image,
							.card-logo,
							.card-image-wrapper {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

@mixin card-hidden-summary-paragraph-container() {
	@media (min-width:720px) {
		&:hover {
			.card-text.card-with-summary {
				background: $card-text-with-summary-hover-bg !important;
				color: $card-text-with-summary-text-color !important;
				text-overflow: ellipsis;
				width: 100% !important;

				.card-title,
				.card-title-et,
				.card-title-field {
					color: $card-text-with-summary-title-color !important;

				}

				.card-summary-wrapper {
					opacity:1 !important;
					height: auto !important;
				}

				.card-date-display-override,
				.card-date,
				.card-footer {
					display: none !important;
				}

				//@media (min-width:$narrow) /*and (max-width:$normal)*/ {
					h3 {
						display: none !important;
					}
				//}
			}

			.card-image +.card-text.card-with-summary,
			.card-logo +.card-text.card-with-summary,
			.card-image-wrapper +.card-text.card-with-summary  {
				position: absolute !important;
				bottom: 0 !important;
				
				.card-summary {
					opacity:1 !important;
					height: auto !important;
				}
			}
		}
	}
}