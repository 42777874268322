.card-resource-authors {
	overflow: hidden;
	vertical-align: middle;
	
	a {
		color: $imperial;	
    &:hover { color:$dingy_dungeon; }
	}
	.author-photo {
		float: left;
		border-radius: 50%;
		overflow: hidden;
		margin-right: $horz-space;
		display: inline-block;
		width: 90px;
		height: 90px;

		img {
			width: 100%;
		}
	}
	.author-name {
		font:$side-row-card-title-font;
		font-weight: 300;
	}
	
	.author-photo + .author-name { padding-top: 10px; }

}
