.view-home.view-display-id-featured {
	.latest-view-switch {
		margin-bottom: 30px;
		width: 100%;
		
		@include media (540px) {
			display: flex;
			border-bottom: 4px solid $pastel_gray;
		}
		
		.view-mode-switch {
			font: 300 1.333rem/1 $title-font-family;
			color: $davys_grey;
			display: block;
			padding: 5px 20px;
			margin-bottom: 10px;
			border-left: 4px solid transparent;
			
			@include media (540px) {
				border: none;
				border-bottom: 4px solid transparent;
				padding: 0 20px 15px;
				margin-bottom: -4px;
				display: inline-block;
			}
			
			&.active,
			&.active:hover {
				color: $maximum_purple;
				border-left: 4px solid $maximum_purple;
				
				@include media (540px) {
					border: none;
					border-bottom: 4px solid $maximum_purple;
				}
			}
			&:hover {
				color: $dingy_dungeon;
				cursor:pointer;
			}
		}
	}
	
	
	& > .view-content-main,
	.attachment .view-display-id-policy_media,
	.attachment .view-display-id-blogs,
	.attachment .view-display-id-resources {
		display:none;
	}
	
	&.view-mode-featured {
		.view-content {
			display:block;
		}
	}
	&.view-mode-policy_media {
		.attachment .view-display-id-policy_media {
			display:block;
		}
	}
	&.view-mode-blogs {
		.attachment .view-display-id-blogs {
			display:block;
		}
	}
	&.view-mode-resources {
		.attachment .view-display-id-resources {
			display:block;
		}
	}
}
