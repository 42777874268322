#mc_embed_signup {
	box-sizing: border-box;
	
	.mc-fields-group {
		float: left;
		width: calc(100% - 105px);		
		padding: 0;
		
		@media (min-width:720px) and (max-width:880px) {
			float: none;
			width: 100%;
		}
		
		label {
			visibility: hidden;
			height: 0;
		}
		#mce-EMAIL {
			border-radius: 0;
			color: white;
			height: 48px;
			font: normal 1.111rem/1rem $base-font-family;
			padding: 0 22px 0 5px;
			background: transparent;
			box-shadow: none;
			border: none;
			border-bottom: 1px solid $sonic_silver;
		}
		
		//ORIGINAL 20%
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: lighten($secondary,60%);
		}
		::-moz-placeholder { /* Firefox 19+ */
			color: lighten($secondary,60%);
		}
		:-ms-input-placeholder { /* IE 10+ */
			color: lighten($secondary,60%);
		}
		:-moz-placeholder { /* Firefox 18- */
			color: lighten($secondary,60%);
		}
		
		
		input {		
			
			&.mce_inline_error {
				border-width: 2px;
			}
		}
	}

	.mc-submit-button {
		float: left;
		margin-left: 5px;
		
		@media (min-width:720px) and (max-width:880px) {
			//float: right;
			margin: 10px 0 0;
		}
		
		input[type="submit"] {
			border-radius: 6px;
			font: normal 1.222rem/1rem $title-font-family;
			height: 48px;
			margin: 0px 0 0;
			padding: 0 15px;
			//text-transform: uppercase;
			width: 100px;
			color: white;
			background-color: $dingy_dungeon;
			
			&:hover {
				color: $dingy_dungeon;
				background-color: white;
			}
		}
	}
}

