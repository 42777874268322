.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	max-width: 700px;
	padding: 0 130px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	min-height: 44px;
	
//	@media screen and (max-width: $narrow) {
//		font-size: 0.750em;
//	}
	
	li {
		padding: 0;
		
		&.pager-item {
			display: inline-block;
			margin: 7px 3px;

			a {
				color: $action-default;
				font-weight: normal;
				padding:0.1em 0em;
				background: white;
				display: block;
				border-radius: 2px;
				width: 30px;
				height: 30px;

				&:hover {
					background:$action-default;
					color:white;
				}
			}
			
			@media screen and (max-width: $narrow) {
				display: none;
			}
		}
		
		&.pager-current {
			background: $action-default;
			color:white;
			font-weight: normal;
			padding:0.1em 0em;
			display: inline-block;
			margin: 7px 3px;
			border-radius: 2px;
			width: 30px;
			height: 30px;
			
			@media screen and (max-width: $narrow) {
				display: none;
			}
		}
		
		
		&.pager-next {
			right: 65px;
//			@media screen and (max-width: $narrow) {
//				right: 35px;
//			}
			a {
				background: $arrow-pink-right 50% 50% / auto 22px no-repeat;

				&:hover {
					background: $arrow-white-right 50% 50% / auto 22px no-repeat;
				}
			}
		}
		&.pager-last {
			right: 0;
			a {
				background: $arrow-pink-right-last 50% 50% / auto 22px no-repeat;

				&:hover {
					background: $arrow-white-right-last 50% 50% / auto 22px no-repeat;
				}
			}
		}
		&.pager-previous {
			left: 65px;
//			@media screen and (max-width: $narrow) {
//				left: 35px;
//			}
			a {
				background: $arrow-pink-left 50% 50% / auto 22px no-repeat;

				&:hover {
					background: $arrow-white-left 50% 50% / auto 22px no-repeat;
				}
			}
		}
		&.pager-first {
			left: 0;
			a {
				background: $arrow-pink-left-last 50% 50% / auto 22px no-repeat;

				&:hover {
					background: $arrow-white-left-last 50% 50% / auto 22px no-repeat;
				}
			}
		}
		&.pager-next,
		&.pager-last,
		&.pager-previous,
		&.pager-first {
			position: absolute;
			a {
				display: block;
				background-color: white;
				//padding: 12px 16px;
				border: $tertiary-border;
				border-radius: $tertiary-border-radius;
				text-indent: -9999px;
				width: 46px;
				height: 46px;
				
				&:hover {
					background-color: $action-default;
				}
			}
		}
		
		&.pager-last,
		&.pager-first {
			a {
				width: 54px;
				height: 46px;
			}
		}
	}
}