// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #53565A; /* black */ 
$secondary         : #2C2C2C; /* grey */ 



// /* MAIN PALETTE */ //
$charleston_green		: #2C2C2C;
$imperial						: #642F6C;
$dark_purple				: #3D0B37;
$dingy_dungeon			: #C63663;



// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa; /* pale_grey */
$pale_grey_two     : #e4e8eb; /* pale_grey_two */ 			/* MOBILE MENU - TO DELETE */
$battleship_grey   : #6b7280; /* battleship_grey */ 		/* MOBILE MENU - TO DELETE */


$black             : #000;
$grey              : #666;
$white             : #FFF;



$razzmic_berry				: #7B4D81;
$platinum							: #E2D9E4;
$isabelline						: #EFEFF0;
// $snow                 : #FAF7F9;
$snow									: #F7F7F7;
$united_nations_blue	: #4F98E1;
$bleu_de_france				: #418FDE;
$davys_grey						: #53565A;
$nickel								: #706E70;
$ghost_white					: #F8FBFE;
$sonic_silver					: #75787B;
$pastel_gray					: #C8C9C7;
$green_blue						: #266FB8;
$green-pigment				: #00B74F;				
$azureish_white				: #CDE1F6;
$glitter							: #E1EDFB;				
$azureish_white2			: #D9E9F8;
$maximum_purple				: #78437F;
$pomp_and_power				: #896492;

$lavender_blush				: #FAEFF3;
$faq-bg-color					: rgba(65,143,222,0.08); // pinkrgba(198,54,99,0.08);




// MENU
$middle_red					: #E28871;
$pastel_pink				: #E2B1A5;
$dust_storm					: #E2C7C0;
$timberwolf					: #E2D8D5;

$imperial						: #612F6D;
$razzmic_berry			: #784D82;
//$pomp_and_power
//$dark_purple
$gainsboro					: #E1D9E4;



// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $dingy_dungeon;
$action-hover        : $dark_purple;
$action-active       : $dark_purple; 
$action-active-hover : $dark_purple; 

$action-alt-default  			: $dark_purple;
$action-alt-hover    			: $dingy_dungeon;
$action-alt-active      	: $dingy_dungeon; 
$action-alt-active-hover	: $dingy_dungeon; 


// /* SHADOWS */ //
$box-shadow : 0 0 8px 0 rgba(0,0,0,0.1);
$box-alt-shadow : 0 4px 8px 0 rgba(0,0,0,0.1);


// /* BORDERS */ //
$primary-border-color          : $imperial; //rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : transparent; //ba(44, 63, 78,0.1);
$secondary-border-color-radius : 0 0 6px 6px;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : rgba(200,201,199,0.4);
$tertiary-border-radius        : 6px;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : none; //1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : #F5F0F3;
$base-accent-color        : #477DCA;
$table-border-color       : #B5B3B5;
$table-cell-border        : 1px solid $table-border-color;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $platinum;
$table-header-border      : 1px solid #9D81A5;
$table-hover-bg-color     : lighten($platinum, 10%);
// $table-stripe-bg          : darken($table-bg-color, 4);
// $table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-stripe-bg          : $snow;
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1.250em;
$table-caption-bg-color   : transparent;
$table-caption-text-color : $dark_purple;
$table-caption-border     : none;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;


// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : none;
$form-box-shadow-focus : none; 
$form-border-color     : $isabelline;
$form-border           : 2px solid $isabelline;
$form-border-radius    : 6px;

$button-bg             : $action-default;
$button-hover-bg       : $action-hover;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 6px;
$button-hover-text     : white;
$button-padding				 : 0.667rem 1.111rem;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : $snow;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;

// /* BLOCKQUOTE */ //
$blockquote-text-color : $nickel;
$blockquote-text-alt-color : $dark_purple;

// /* CAPTION */ //
$caption-text-color			: $davys_grey;
$caption-bg-color				: $isabelline;
