//@import url(https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:400,400i,700,700i);
@import url("https://use.typekit.net/qpa3efa.css");
 
$base-font-family      		: 'source-sans-pro', sans-serif;
$title-font-family    		: 'astoria-sans', sans-serif;
$subtitle-font-family 	 	: 'astoria-sans', sans-serif;


$base-font-size						: 18px; //16px
$base-line-height					: 1.5;  

$base-font-size-normal		: 18px;

$header-menu-font 				: /*0.875rem*/ 300 1.111rem/1 $title-font-family; //1.250rem/1 
$header-search-field-font : 300 0.889rem/1.286 $base-font-family; //0.875rem/1.286 

$nav-base-font        	 	: 300 1.222rem/1.3 $title-font-family; //1.25rem/1.3
$nav-base-font-smaller 		: 300 1rem/1.375 $title-font-family; //1rem/1.375
$nav-sub-base-font        : 300 1rem/1.3 $base-font-family; //1rem/1.3

$breadcrumb-font 					: /*1rem*/ 0.778rem/1 $title-font-family; //0.889rem/1

$section-row-title-font 					: 300 2.0rem/1.25 $title-font-family; // /* Nugget title */ 2.250rem/1.25
$section-row-title-font-smaller 	: 300 1.778rem/1.25 $title-font-family; // /* Nugget title */ 2rem/1.25

$side-row-title-font 			: 300 1.75em/1.21428571428571 $title-font-family; // /* Sidebar nugget title */
$side-row-card-title-font  : 500 1.22em/1.363636363636364 $title-font-family;

$card-title-font-family		: $title-font-family;
$card-title-font        	: normal 1.333rem/1.22222222222222 $card-title-font-family; //1.5
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.333rem/1 $base-font-family; //1.5rem/1
$card-feed-link-font			: 300 1.111rem/1 $base-font-family;

$button-link-font 				: 300 1rem/1 $base-font-family;

/* FORMS */
$button-font 							: normal 1.111rem/1 $title-font-family;