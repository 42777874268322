.external-jobs-header {
  .card-organisation-wrapper {
    .card-job-organisation,
    .card-job-location {
      display: inline-block;
    }
    
//    .card-job-organisation:after {
//      content:",";
//      display:inline-block;
//      height:16px;
//      margin:0 0 -2px 10px;
//      width:14px;
//    }
  }
	
	& + .external-jobs-description {
		margin: 20px -20px -20px;
		width: calc(100% + 40px);

		@media (min-width: $narrow) {
			margin: 30px -30px -30px;
			width: calc(100% + 60px);
		}
    
		.card-body-wrapper {
			padding: 20px 20px 0;
			border-top: 30px solid $snow;

			@media (min-width: $narrow) {			
				padding: 30px; 
			}

			.card-description *:last-child {
				margin-bottom: 0;
			}
		}
	}
}