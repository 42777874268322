.row-max-2,
.row-max-3,
.row-max-4 ,
.row-max-6 {
  .card-orgs-view,
  .view-display-id-grid_node  {
    .views-row {
      width:100%;
      float:none;
      margin-right:0;
    }
  }
}

@media (min-width:540px) and (max-width:$narrow) {
	 .row-max-2 .card-orgs-view  .view:not(.view-display-id-list) .card-organisations article,
  .row-max-3 .card-orgs-view .view:not(.view-display-id-list) .card-organisations article,
  .row-max-4 .card-orgs-view .view:not(.view-display-id-list) .card-organisations article,
  .row-max-6 .card-orgs-view .view:not(.view-display-id-list) .card-organisations article,
  .row-max-2 .view-display-id-grid_node .card-organisations article,
  .row-max-3 .view-display-id-grid_node .card-organisations article,
  .row-max-4 .view-display-id-grid_node .card-organisations article,
  .row-max-6 .view-display-id-grid_node .card-organisations article,
  .view-display-grid .row-max-2 .card-organisations article,
  .view-display-grid .row-max-3 .card-organisations article,
  .view-display-grid .row-max-4 .card-organisations article,
  .view-display-grid .row-max-6 .card-organisations article {
    @include span-columns(6);
    &:nth-child(2n) { margin-right:0 !important; }
    &:nth-child(2n+1) { clear:left; }
  }
}

@media (min-width:$narrow) {
  .row-max-2 .view-display-id-grid_node .card-organisations article,
  .row-max-2 .card-orgs-view  .view:not(.view-display-id-list) .card-organisations article,
  .view-display-grid .row-max-2 .card-organisations article {
    @include span-columns(6);
    &:nth-child(2n) { margin-right:0 !important; }
    &:nth-child(2n+1) { clear:left; }
  }
  .row-max-3 .view-display-id-grid_node .card-organisations article,
  .row-max-3 .card-orgs-view .view:not(.view-display-id-list) .card-organisations article,
  .view-display-grid .row-max-3 .card-organisations article {
    @include span-columns(4);
    &:nth-child(3n) { margin-right:0 !important; }
    &:nth-child(3n+1) { clear:left; }
  }
  .row-max-4 .view-display-id-grid_node .card-organisations article,
  .row-max-4 .card-orgs-view .view:not(.view-display-id-list) .card-organisations article,
  .view-display-grid .row-max-4 .card-organisations article {
    @include span-columns(3);
    &:nth-child(4n) { margin-right:0 !important; }
    &:nth-child(4n+1) { clear:left; }
  }
  .row-max-6 .view-display-id-grid_node .card-organisations article,
  .row-max-6 .card-orgs-view .view:not(.view-display-id-list) .card-organisations article,
  .view-display-grid .row-max-6 .card-organisations article {
    @include span-columns(2);
    &:nth-child(6n) { margin-right:0 !important; }
    &:nth-child(6n+1) { clear:left; }
  }
	
	
	.view-organisations.view-display-id-list .card-img-wrapper {
		@include span-columns(3);
	}
}
.row-max-2 .view-organisations .card-summary,
.row-max-3 .view-organisations .card-summary,
.row-max-4 .view-organisations .card-summary,
.row-max-6 .view-organisations .card-summary {
  display: none;
}
