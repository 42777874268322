/* LINKS FLEXI BOXES */
.section-row:not(.view-type-slider) .view-links,
.section-row:not(.view-type-slider) .view-links-flexi {
	.views-row {		
		.card {
			@include card-hidden-summary-paragraph-container;
		}	
	}	
}

.section-row:not(.view-type-slider) .view-links-flexi {
	.views-row {		
		.card {
			.card-text,
			.card-image {
				width: 100% !important;
			}
		}		
		
		.views-row {
			width: 100% !important;
			clear: none !important;
			margin: 0 !important;

			@include card-no-radious();
		}
		
		&.views-row-last .views-row {
			@include card-radious-right();
		}
	}
}