@mixin card-no-radious() {
	.card {
		border-radius: 0;
		
		&.card-with-image {
			border-radius: 0;
		}
	}
}

@mixin card-radious-left() {
	.card {
		border-radius: 0 0 0 6px;
		
		&.card-with-image {
			border-radius: 6px 0 0 6px;
		}
	}
}

@mixin card-radious-right() {
	.card {		
		border-radius: 0 0 6px 0;
		
		&.card-with-image {
			border-radius: 0 6px 6px 0;
		}
	}
}

@mixin card-radious-all() {
	.card {
		border-radius: 0 0 6px 0;
		
		&.card-with-image {
			border-radius: 6px 0 6px 6px;
		}
	}
}
 
@mixin card-radious-1-card() {
	.card {
		border-radius: 0 0 6px 6px;
		
		&.card-with-image {
			border-radius: 6px 6px 6px 6px;
		}
	}
}

//@mixin card-radious-text() {
//	.card .card-text {
//		border-radius: 0 0 6px 6px;
//	}
//}
//
//@mixin card-radious-image() {
//	.card .card-image {
//		border-radius: 6px;
//	}
//}

@mixin card-radious-content-image() {
	border-radius: 6px;
  overflow: hidden;
		
	&.image-with-caption {		
		border-radius: 6px 6px 0 0;
	}
}